import React from "react";
import FAQ from "../sections/faq";
import { Button } from "../ui/button";
import PageHeader from "../ui/custom/page_header";
import screenprint_transfer from "../../assets/png/screenprint_transfer.png";
import dtf_3 from "../../assets/png/dtf_3.jpg";
import dtf_4 from "../../assets/png/dtf_4.jpg";
import dtf_6 from "../../assets/png/dtf_6.jpg";
import check from "../../assets/svg/check.svg";
import close from "../../assets/svg/close.svg";
import { useTranslation } from "../../TranslationContext";

const Dtf = () => {
  const translations = useTranslation();

  return (
    <div>
      <PageHeader
        title={translations.dtf.pageHeader.title}
        subtitle={translations.dtf.pageHeader.subtitle}
        background="default"
      />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start py-[92px] px-20 md:px-10 box-border text-left text-4xl lg:text-3xl xs:text-2xl text-slate-900 font-text-lg-font-normal">
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="w-full flex flex-row md:flex-col-reverse items-center justify-center max-w-[1200px]">
            <div className="w-full md:max-w-[400px] self-stretch flex flex-col items-center justify-center">
              <img
                className="w-full min-w-[320px] relative h-fit overflow-hidden shrink-0"
                alt=""
                src={screenprint_transfer}
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-6">
              <b className="self-stretch relative leading-[150%]">
                <h1>{translations.dtf.descriptionSection.title}</h1>
              </b>
              <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-500">
                <p className="m-0">
                  {translations.dtf.descriptionSection.description1}
                </p>
                <br />
                <p className="m-0">
                  {translations.dtf.descriptionSection.description2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-white flex flex-col items-center justify-center pt-18 px-20 md:px-10 sm:px-6 pb-24 box-border gap-16 text-center text-4xl lg:textl-3xl xs:text-2xl text-slate-900">
        <div className="w-full flex flex-col items-center justify-start max-w-[800px]">
          <b className="self-stretch relative leading-[150%]">
            {translations.dtf.prosAndCons.title}
          </b>
        </div>
        <div className="w-full flex flex-row md:flex-col items-start justify-center gap-10 max-w-[1080px] text-left text-xl md:text-lg text-slate-950">
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.dtf.prosAndCons.pros.price}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.dtf.prosAndCons.pros.priceDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.dtf.prosAndCons.pros.fullcolor}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.dtf.prosAndCons.pros.fullcolorDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.dtf.prosAndCons.pros.volume}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.dtf.prosAndCons.pros.volumeDescription}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.dtf.prosAndCons.cons.durability}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.dtf.prosAndCons.cons.durabilityDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.dtf.prosAndCons.cons.gamut}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.dtf.prosAndCons.cons.gamutDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.dtf.prosAndCons.cons.compatibility}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.dtf.prosAndCons.cons.compatibilityDescription}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-slate-950 overflow-hidden flex flex-row items-start justify-center pt-16 pb-24 px-24 box-border text-center text-3xl text-white font-text-lg-font-normal">
        <div className="flex-1 flex flex-col items-center justify-start gap-14 max-w-[1400px]">
          <div className="w-full flex flex-col items-center justify-start gap-4 max-w-[800px]">
            <div className="self-stretch flex flex-col items-center justify-start gap-1">
              <div className="self-stretch relative leading-[150%] font-medium text-gradient">
                {translations.dtf.heatpressSection.title}
              </div>
              <div className="self-stretch relative text-4xl sm:text-3xl leading-[150%] font-extrabold">
                {translations.dtf.heatpressSection.callForAction}
              </div>
            </div>
            <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-300 px-4">
              {translations.dtf.heatpressSection.description}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center px-9">
            <Button
              variant="default"
              size="xl"
              className="uppercase w-[260px]"
              onClick={() =>
                window.open(
                  translations.dtf.heatpressSection.buttonLink,
                  "_blank"
                )
              }
            >
              {translations.dtf.heatpressSection.button}
            </Button>
          </div>
          <div className="w-full self-stretch flex flex-col items-center justify-center">
            <div className="w-full self-stretch flex flex-row md:flex-col items-center justify-center gap-6">
              <img
                className="w-full max-w-[451.56px] sm:max-w-[300px] relative h-fit overflow-hidden shrink"
                alt=""
                src={dtf_3}
              />
              <img
                className="w-full max-w-[451.56px] sm:max-w-[300px] relative h-fit overflow-hidden shrink"
                alt=""
                src={dtf_6}
              />
              <img
                className="w-full max-w-[451.56px] relative h-fit overflow-hidden shrink lg:hidden"
                alt=""
                src={dtf_4}
              />
            </div>
          </div>
        </div>
      </div>
      <FAQ isTitleVisible={true} />
    </div>
  );
};

export default Dtf;
