import React from 'react';

interface CloseIconProps {
  className?: string;
  width?: string | number;
  height?: string | number;
  color?: string;
}

const CloseIcon: React.FC<CloseIconProps> = ({ className, width = "24", height = "24", color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" className={className}>
    <g clipPath="url(#clip0_415_4940)">
      <mask id="mask0_415_4940" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_415_4940)">
        <path d="M11.9952 13.405L7.09517 18.305C6.91183 18.4883 6.6785 18.58 6.39517 18.58C6.11183 18.58 5.8785 18.4883 5.69517 18.305C5.51183 18.1217 5.42017 17.8883 5.42017 17.605C5.42017 17.3217 5.51183 17.0883 5.69517 16.905L10.5952 12.005L5.69517 7.10499C5.51183 6.92166 5.42017 6.68833 5.42017 6.40499C5.42017 6.12166 5.51183 5.88833 5.69517 5.70499C5.8785 5.52166 6.11183 5.42999 6.39517 5.42999C6.6785 5.42999 6.91183 5.52166 7.09517 5.70499L11.9952 10.605L16.8952 5.70499C17.0785 5.52166 17.3118 5.42999 17.5952 5.42999C17.8785 5.42999 18.1118 5.52166 18.2952 5.70499C18.4785 5.88833 18.5702 6.12166 18.5702 6.40499C18.5702 6.68833 18.4785 6.92166 18.2952 7.10499L13.3952 12.005L18.2952 16.905C18.4785 17.0883 18.5702 17.3217 18.5702 17.605C18.5702 17.8883 18.4785 18.1217 18.2952 18.305C18.1118 18.4883 17.8785 18.58 17.5952 18.58C17.3118 18.58 17.0785 18.4883 16.8952 18.305L11.9952 13.405Z" fill={color || "#1E293B"}/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_415_4940">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default CloseIcon;