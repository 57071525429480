import React from "react";

const DtfIcon: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
      fill="url(#paint0_linear_1308_45433)"
    />
    <g clip-path="url(#clip0_1308_45433)">
      <mask
        id="mask0_1308_45433"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="24"
        height="24"
      >
        <path d="M12 12H36V36H12V12Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1308_45433)"></g>
      <g clip-path="url(#clip1_1308_45433)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.12 20.88C32.56 20.32 31.8 20 31 20H30V17C30 16.2 29.68 15.44 29.12 14.88C28.56 14.32 27.8 14 27 14H21C20.2 14 19.44 14.32 18.88 14.88C18.32 15.44 18 16.2 18 17V20H17C16.2 20 15.44 20.32 14.88 20.88C14.32 21.44 14 22.2 14 23V27C14 27.8 14.32 28.56 14.88 29.12C15.44 29.68 16.2 30 17 30H18V31C18 31.8 18.32 32.56 18.88 33.12C19.44 33.68 20.2 34 21 34H27C27.8 34 28.56 33.68 29.12 33.12C29.68 32.56 30 31.8 30 31V30H31C31.8 30 32.56 29.68 33.12 29.12C33.68 28.56 34 27.8 34 27V23C34 22.2 33.68 21.44 33.12 20.88ZM20 17C20 16.73 20.11 16.48 20.29 16.29C20.48 16.11 20.73 16 21 16H27C27.27 16 27.52 16.11 27.71 16.29C27.89 16.48 28 16.73 28 17V20H20V17ZM28 31C28 31.27 27.89 31.52 27.71 31.71C27.52 31.89 27.27 32 27 32H21C20.73 32 20.48 31.89 20.29 31.71C20.11 31.52 20 31.27 20 31V27C20 26.73 20.11 26.48 20.29 26.29C20.48 26.11 20.73 26 21 26H27C27.27 26 27.52 26.11 27.71 26.29C27.89 26.48 28 26.73 28 27V31ZM32 27C32 27.27 31.89 27.52 31.71 27.71C31.52 27.89 31.27 28 31 28H30V27.52 27.89 31.27 28 31 28H30V27C30 26.2 29.68 25.44 29.12 24.88C28.56 24.32 27.8 24 27 24H21C20.2 24 19.44 24.32 18.88 24.88C18.32 25.44 18 26.2 18 27V28H17C16.73 28 16.48 27.89 16.29 27.71C16.11 27.52 16 27.27 16 27V23C16 22.73 16.11 22.48 16.29 22.29C16.48 22.11 16.73 22 17 22H31C31.27 22 31.52 22.11 31.71 22.29C31.89 22.48 32 22.73 32 23V27Z"
          fill="url(#paint1_linear_1308_45433)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1308_45433"
        x1="0"
        y1="0"
        x2="57.7461"
        y2="27.6332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBFAFC" />
        <stop offset="0.354167" stop-color="#FFF4FF" />
        <stop offset="0.677083" stop-color="#FEE6FF" />
        <stop offset="0.911458" stop-color="#FEF1FF" />
        <stop offset="1" stop-color="#FCF7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1308_45433"
        x1="14"
        y1="14"
        x2="38.0609"
        y2="25.5138"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#A554E5" />
        <stop offset="0.666667" stop-color="#FF00D6" />
        <stop offset="1" stop-color="#A554E5" />
      </linearGradient>
      <clipPath id="clip0_1308_45433">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
      <clipPath id="clip1_1308_45433">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(14 14)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DtfIcon;
