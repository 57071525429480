import * as React from "react";

import { cn } from "src/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  note?: string;
  placeholder?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, note, placeholder, ...props }, ref) => {
    return (
      <div
        className={cn(
          "relative w-full h-full flex flex-col items-start justify-start gap-[8px] text-left text-sm text-slate-500",
          className
        )}
      >
        <div className="self-stretch relative leading-[150%] font-bold text-slate-800 text-md">
          {label}
        </div>
        <div className="self-stretch flex-1 rounded-md bg-slate-50 flex flex-row items-start justify-start py-boundvariablesdata3 px-boundvariablesdata2 border-none">
          <textarea
            className="flex-1 h-full flex-row justify-start self-stretch relative leading-[150%] flex items-center resize-none rounded-lg border border-slate-200 bg-slate-50 px-4 py-2"
            ref={ref}
            placeholder={placeholder}
            {...props}
          />
        </div>
        {note && (
          <div className="self-stretch relative text-sm leading-[150%] text-slate-500 pt-1">
            {note}
          </div>
        )}
      </div>
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
