import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import Cookies from "js-cookie";
import PageHeader from "../ui/custom/page_header";
import FileUpload from "../ui/custom/file_upload";
import { Input } from "../ui/input";
import { Checkbox } from "../ui/checkbox";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { AlertCircle } from "lucide-react";
import email_sent from "../../assets/svg/email_sent.svg";
import email_error from "../../assets/svg/email_error.svg";
import info_outline from "../../assets/svg/info_outline.svg";
import { useTranslation } from "../../TranslationContext";

declare global {
  interface Window {
    gtag_report_conversion: (url?: string) => void;
  }
}

const Order = () => {
  const translations = useTranslation();
  const graphicRef = useRef<HTMLDivElement | null>(null);
  const invoiceRef = useRef<HTMLDivElement | null>(null);
  const consentRef = useRef<HTMLDivElement | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [note, setNote] = useState("");

  const [isCompany, setIsCompany] = useState(false);
  const [nameAndSurname, setNameAndSurname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [consent, setConsent] = useState(false);

  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isError, setIsError] = useState(false);

  let dtfLengthRegex = new RegExp(
    /^(0\.[2-9]|[1-9][0-9]?(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/
  );
  let nameRegex = new RegExp(/^$|^[\p{L}'-]+ [\p{L}'-]+$/u);
  let companyRegex = new RegExp(/^.{0,100}$/);
  let emailRegex = new RegExp(/^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  let phoneNumberRegex = new RegExp(/^$|^\+?[\d\s]{8,15}$/);

  const [isFileSubmited, setIsFileSubmited] = useState(true);

  const [isNameVerified, setIsNameVerified] = useState(true);
  const [isCompanyVerified, setIsCompanyVerified] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isNumberVerified, setIsNumberVerified] = useState(true);

  const [consentAlert, setConsentAlert] = useState(false);
  const [graphicAlert, setGraphicAlert] = useState(false);
  const [invoiceAlert, setInvoiceAlert] = useState(false);
  const [companyAlert, setCompanyAlert] = useState(false);
  const [deliveryAlert, setDeliveryAlert] = useState(false);
  const [dateAlert, setDateAlert] = useState(false);
  const [alertTimer, setAlertTimer] = useState(false);

  const languageMap: { [key: string]: string } = {
    SK: "sk",
    CZ: "cz",
    DE: "de",
    EN: "en",
    default: "sk",
  };

  const getLanguageFromCookies = (): string => {
    // Check for language cookie first
    const langCookie = Cookies.get("lang");
    if (langCookie && languageMap[langCookie]) {
      return languageMap[langCookie];
    }

    return languageMap["default"];
  };

  const validateAlwaysRequiredFields = () => {
    setIsNameVerified(
      Boolean(nameAndSurname && nameRegex.test(nameAndSurname))
    );
    setIsEmailVerified(Boolean(email && emailRegex.test(email)));
    setIsNumberVerified(
      Boolean(phoneNumber && phoneNumberRegex.test(phoneNumber))
    );
  };

  const validateFileSubmit = () => {
    setIsFileSubmited(Boolean(selectedFile));
  };

  const handleFileChange = async (file: File | null) => {
    if (file === null) {
      console.log("No file selected.");
      return;
    }

    setSelectedFile(file);

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async (event) => {
      const arrayBuffer = (event.target as FileReader).result as ArrayBuffer;

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Here you can set state variables or perform other actions based on the page sizes
    };
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    validateAlwaysRequiredFields();
    validateFileSubmit();

    // Check if consent is true
    if (!consent) {
      setConsentAlert(true);
    } else {
      setConsentAlert(false);
    }

    if (!isFileSubmited) {
      setGraphicAlert(true);
    } else {
      setGraphicAlert(false);
    }

    if (
      !Boolean(nameAndSurname && nameRegex.test(nameAndSurname)) ||
      !Boolean(email && emailRegex.test(email)) ||
      !Boolean(phoneNumber && phoneNumberRegex.test(phoneNumber))
    ) {
      setInvoiceAlert(true);
    } else {
      setInvoiceAlert(false);
    }

    setAlertTimer(true);

    if (!isFileSubmited) {
      graphicRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      !Boolean(nameAndSurname && nameRegex.test(nameAndSurname)) ||
      !Boolean(email && emailRegex.test(email)) ||
      !Boolean(phoneNumber && phoneNumberRegex.test(phoneNumber))
    ) {
      invoiceRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (!consent) {
      consentRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    setIsWaitingForResponse(true);
    window.scrollTo(0, 100);

    // Convert selectedFile to Base64
    let selectedFileBase64 = "";
    if (selectedFile) {
      selectedFileBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(selectedFile);
      });
    }

    // If everything is correct, create JSON and print it to console
    const payload = {
      note,
      nameAndSurname,
      company: company ? company : "",
      email,
      phoneNumber,
      consent,
      selectedFile: selectedFileBase64,
      selectedFilename: selectedFile
        ? selectedFile.name
        : translations.order.graphics,
      language: getLanguageFromCookies(),
      emailTitle: translations.order.emailTitle,
    };

    //console.log(payload);

    const url =
      "https://europe-central2-nexprim-5569f.cloudfunctions.net/contact-form";

    //console.log(JSON.stringify(payload));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      console.log(response);

      if (!response.ok) {
        setIsError(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // or response.text() if the response is not a JSON object
      if (data.message === "Email sent") {
        setIsEmailSent(true);
        const cookieValue = Cookies.get("cookies-preference");
        let marketingConsent = false;

        if (cookieValue) {
          try {
            const preferences = JSON.parse(cookieValue);
            marketingConsent = preferences.marketing === true; // Note the strict equality to boolean true
          } catch (error) {
            console.error("Error parsing cookies-preference:", error);
          }
        }
        if (marketingConsent) {
          window.gtag_report_conversion("https://sietotlac.nexprim.sk/");
        }
      }
      console.log("Response data:", data);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsError(true);
    } finally {
    }
  };

  useEffect(() => {
    if (alertTimer) {
      const timer = setTimeout(() => {
        // Reset all alerts
        setConsentAlert(false);
        setGraphicAlert(false);
        setInvoiceAlert(false);

        // Reset alertTimer
        setAlertTimer(false);
      }, 5000);

      return () => clearTimeout(timer); // This will clear the timer if the component unmounts before the timer finishes
    }
  }, [alertTimer]);

  useEffect(() => {
    if (isWaitingForResponse || isEmailSent || isError) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Allow scrolling
      document.body.style.overflow = "auto";
    }
  }, [isWaitingForResponse, isEmailSent, isError]);

  return (
    <div className="font-outfit">
      {(consentAlert ||
        graphicAlert ||
        invoiceAlert ||
        companyAlert ||
        deliveryAlert ||
        dateAlert) && (
        <div className="fixed top-0 left-1/2 transform -translate-x-1/2 z-50 inline-flex flex-col pt-3 gap-3 items-center justify-start">
          {graphicAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>{translations.order.graphicAlert.title}</AlertTitle>
                <AlertDescription>
                  {translations.order.graphicAlert.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
          {invoiceAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>{translations.order.invoiceAlert.title}</AlertTitle>
                <AlertDescription>
                  {translations.order.invoiceAlert.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
          {consentAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>{translations.order.consentAlert.title}</AlertTitle>
                <AlertDescription>
                  {translations.order.consentAlert.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
        </div>
      )}

      {isWaitingForResponse && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-28 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.order.isWaitingForResponse.title}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  <span>
                    {
                      translations.order.isWaitingForResponse
                        .descriptionHighlight
                    }
                  </span>
                  <span className="font-medium text-gradient">
                    {translations.order.isWaitingForResponse.description}
                  </span>
                </div>
              </div>
              <span className="loader-38"> </span>
            </div>
          </div>
        </div>
      )}
      {isEmailSent && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-start gap-28 sm:gap-10 py-20">
              <div className="w-full flex flex-col gap-10 items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-[10px]">
                  <div className="relative leading-[150%] font-semibold">
                    {translations.order.isEmailSent.title}
                  </div>
                  <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                    {translations.order.isEmailSent.description}
                  </div>
                </div>
                <div className="w-auto relative rounded-lg bg-light-gradient flex flex-row items-center justify-center px-6 py-5 box-border gap-4 text-left text-lg sm:text-base text-slate-900 font-normal">
                  <img
                    className="w-8 relative h-8 overflow-hidden shrink-0"
                    alt="nexprim-info"
                    src={info_outline}
                  />
                  <div className="flex-1 relative leading-[150%] whitespace-pre-wrap">
                    {translations.order.isEmailSent.spam}
                    <span className="font-semibold text-gradient">
                      {translations.order.isEmailSent.spamHighlight}
                    </span>
                    {translations.order.isEmailSent.spam2}
                  </div>
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_sent} />
              <RouterLink to="/">
                <Button
                  variant="primary"
                  size="default"
                  className="uppercase"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsEmailSent(false);
                    setIsWaitingForResponse(false);
                  }}
                >
                  {translations.order.isEmailSent.homeButton}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      )}
      {isError && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-20 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.order.isError.title}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  {translations.order.isError.descriptionHighlight}
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_error} />
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex flex-row items-end justify-center gap-6">
                  <RouterLink
                    to="/contact"
                    style={{ display: "inline-block", padding: 0, margin: 0 }}
                  >
                    <Button
                      variant="primary"
                      size="default"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setIsError(false);
                        setIsEmailSent(false);
                        setIsWaitingForResponse(false);
                      }}
                    >
                      {translations.order.isError.contactButton}
                    </Button>
                  </RouterLink>
                  <Button
                    variant="default"
                    size="default_stretch"
                    type="submit"
                    onClick={() => {
                      setIsError(false);
                      setIsEmailSent(false);
                      handleSubmit();
                    }}
                  >
                    {translations.order.isError.tryAgainButton}
                  </Button>
                </div>
                <div className="relative max-w-[500px] text-base sm:text-sm leading-[150%] text-slate-600">
                  {translations.order.isError.contactUs}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <div
          style={{ visibility: isWaitingForResponse ? "hidden" : "visible" }}
        >
          <PageHeader
            title={translations.order.pageHeader.title}
            subtitle={translations.order.pageHeader.subtitle}
            background="default"
          />
          <div className="relative bg-white w-full flex-wrap overflow-hidden flex flex-col items-center justify-center text-left text-inherit text-black font-inherit ">
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                // Assert e.target as an HTMLElement to access tagName
                const target = e.target as HTMLElement;
                if (e.key === "Enter" && target.tagName !== "TEXTAREA") {
                  e.preventDefault();
                }
              }}
            >
              <div className="w-full flex flex-col items-center justify-center py-20 px-20 lg:px-12 md:px-8 sm:px-6">
                <div className="w-full flex flex-col flex-wrap items-center justify-center gap-10 max-w-[768px] px-2">
                  <div className="flex w-full flex-col flex-wrap items-center justify-center gap-4">
                    <div className="self-stretch w-full flex flex-col items-start justify-center gap-4 text-sm text-slate-800">
                      <div className="w-full relative rounded-lg bg-light-gradient flex flex-row items-start justify-start p-4 box-border gap-4 text-left text-sm sm:text-xs text-slate-900 font-normal mb-8">
                        <img
                          className="w-8 relative h-8 overflow-hidden shrink-0"
                          alt=""
                          src={info_outline}
                        />
                        <div className="flex-1 relative leading-[150%] whitespace-pre-wrap">
                          {translations.order.pageHeader.description}
                        </div>
                      </div>
                      <div className="flex flex-col justify-center items-start w-full bg-white">
                        <div className="flex flex-row items-center justify-start text-slate-800">
                          <div className="flex flex-row items-center justify-start">
                            <div className="relative leading-[150%] text-base font-semibold">
                              {translations.order.form.fileUpload}
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full h-full pt-2">
                          <FileUpload
                            uploadText={translations.order.form.fileUploadText}
                            fileSizeText={
                              translations.order.form.fileUploadFileSize
                            }
                            buttonText={
                              translations.order.form.fileUploadButton
                            }
                            onFileChange={(file) => handleFileChange(file)}
                            isVerified={isFileSubmited} // Add this line
                          />
                        </div>
                      </div>
                      <div className="flex justify-center items-center w-full h-72 bg-white">
                        <Textarea
                          label={translations.order.form.noteLabel}
                          placeholder={translations.order.form.notePlaceholder}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-center gap-[12px] align-bottom sm:flex-col">
                        <div className="self-stretch flex-1">
                          <Input
                            type="text"
                            label={translations.order.form.nameAndSurnameLabel}
                            warningText={
                              translations.order.form.nameAndSurnamewarning
                            }
                            validationRule={nameRegex}
                            onValueChange={setNameAndSurname}
                            autoComplete="name"
                            isVerified={isNameVerified}
                          />
                        </div>
                        <div className="self-stretch flex-1">
                          <Input
                            type="text"
                            label={translations.order.form.companyLabel}
                            optional={!isCompany}
                            validationRule={companyRegex}
                            warningText={translations.order.form.companyWarning}
                            onValueChange={setCompany}
                            autoComplete="organization"
                            isVerified={isCompanyVerified}
                          />
                        </div>
                      </div>

                      <div className="self-stretch flex flex-row items-start justify-start gap-[12px] sm:flex-col">
                        <div className="self-stretch flex-1">
                          <Input
                            type="email"
                            label={translations.order.form.emailLabel}
                            warningText={translations.order.form.emailWarning}
                            validationRule={emailRegex}
                            onValueChange={setEmail}
                            autoComplete="email"
                            isVerified={isEmailVerified}
                          />
                        </div>
                        <div className="self-stretch flex-1">
                          <Input
                            type="tel"
                            label={translations.order.form.phoneNumberLabel}
                            warningText={
                              translations.order.form.phoneNumberWarning
                            }
                            validationRule={phoneNumberRegex}
                            onValueChange={setPhoneNumber}
                            autoComplete="tel"
                            isVerified={isNumberVerified}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-center text-sm text-red-400 font-text-sm-font-semibold">
                      <div
                        id="bottom_separator"
                        className="self-stretch flex flex-col items-start justify-center gap-4 text-xs text-slate-500 pt-10"
                      >
                        <Checkbox
                          className="max-w-fit"
                          labelText={
                            <span>
                              {translations.order.form.consent}
                              <a
                                href="/terms-of-service"
                                className="font-semibold underline"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {translations.order.form.terms}
                              </a>
                              {translations.order.form.and}
                              <a
                                href="/privacy-policy"
                                className="font-semibold underline"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {translations.order.form.privacyPolicy}
                              </a>
                              .
                            </span>
                          }
                          onCheckedChange={(checked) =>
                            setConsent(
                              checked !== "indeterminate" ? checked : false
                            )
                          } // Add this line
                        />
                        <Button
                          variant="default"
                          size="default_stretch"
                          type="submit"
                          className="uppercase"
                        >
                          {translations.order.form.priceOffer}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      }
    </div>
  );
};

export default Order;
