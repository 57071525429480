import FAQ from "../sections/faq";
import PageHeader from "../ui/custom/page_header";
import subli from "../../assets/png/subli.png";
import check from "../../assets/svg/check.svg";
import close from "../../assets/svg/close.svg";
import { useTranslation } from "../../TranslationContext";

const Sublimation = () => {
  const translations = useTranslation();
  return (
    <div>
      <PageHeader
        title={translations.sublimation.pageHeader.title}
        subtitle={translations.sublimation.pageHeader.subtitle}
        background="default"
      />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start py-[92px] px-20 md:px-10 box-border text-left text-4xl lg:text-3xl xs:text-2xl text-slate-900 font-text-lg-font-normal">
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="w-full flex flex-row md:flex-col-reverse items-center justify-center max-w-[1200px]">
            <div className="w-full md:max-w-[400px] self-stretch flex flex-col items-center justify-center">
              <img
                className="w-full min-w-[400px] relative h-fit overflow-hidden shrink-0"
                alt=""
                src={subli}
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-6">
              <b className="self-stretch relative leading-[150%]">
                <h1>{translations.sublimation.descriptionSection.title}</h1>
              </b>
              <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-500">
                <p className="m-0">
                  {translations.sublimation.descriptionSection.description1}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-white flex flex-col items-center justify-center pt-18 px-20 md:px-10 sm:px-6 pb-24 box-border gap-16 text-center text-4xl lg:textl-3xl xs:text-2xl text-slate-900">
        <div className="w-full flex flex-col items-center justify-start max-w-[800px]">
          <b className="self-stretch relative leading-[150%]">
            {translations.sublimation.prosAndCons.title}
          </b>
        </div>
        <div className="w-full flex flex-row md:flex-col items-start justify-center gap-10 max-w-[1080px] text-left text-xl md:text-lg text-slate-950">
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.sublimation.prosAndCons.pros.touch}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.sublimation.prosAndCons.pros.touchDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.sublimation.prosAndCons.pros.ironing}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.sublimation.prosAndCons.pros.ironingDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.sublimation.prosAndCons.pros.washing}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.sublimation.prosAndCons.pros.washingDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.sublimation.prosAndCons.pros.color}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.sublimation.prosAndCons.pros.colorDescription}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.sublimation.prosAndCons.cons.textileColor}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.sublimation.prosAndCons.cons
                      .textileColorDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.sublimation.prosAndCons.cons.material}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.sublimation.prosAndCons.cons
                      .materialDescription
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQ isTitleVisible={true} />
    </div>
  );
};

export default Sublimation;
