import React, { FunctionComponent } from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

import OrderIcon from "../../assets/custom_icons/OrderIcon";
import QualityIcon from "../../assets/custom_icons/QualityIcon";
import DeliveryIcon from "../../assets/custom_icons/DeliveryIcon";
import PaymentIcon from "../../assets/custom_icons/PaymentIcon";
import RefundIcon from "../../assets/custom_icons/RefundIcon";
import GraphicsIcon from "../../assets/custom_icons/GraphicsIcon";
import ShirtIcon from "../../assets/custom_icons/ShirtIcon";
import DtfIcon from "../../assets/custom_icons/DtfIcon";
import ScreenprintIcon from "../../assets/custom_icons/ScreenprintIcon";
import ScreenprintThermotransferIcon from "../../assets/custom_icons/ScreenprintThermotransferIcon";
import SublimationIcon from "../../assets/custom_icons/SublimationIcon";
import { useTranslation } from "../../TranslationContext";

interface FAQProps {
  isTitleVisible?: boolean;
}

const FAQ: FunctionComponent<FAQProps> = ({ isTitleVisible = true }) => {
  const translations = useTranslation();
  return (
    <div
      id="faq"
      className="relative bg-white w-full overflow-hidden flex flex-col py-24 px-20 box-border items-center justify-start text-center text-4xl sm:text-3xl text-livid-900 sm:px-12 xs:px-6 xs:py-16 "
    >
      <div className="max-w-[700px] w-full flex flex-col items-center justify-start gap-12 no-underline hover:no-underline">
        {isTitleVisible && (
          <div className="self-stretch flex flex-col items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[125%] font-extrabold flex items-center justify-center w-full ">
              {translations.faq.title}
            </div>
          </div>
        )}
        <div className="w-full">
          <Accordion type="single" collapsible>
            <AccordionItem className="border-0" value="item-1-1">
              <AccordionTrigger variant="bold" icon={OrderIcon}>
                {translations.faq.section1.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-1-6">
                    <AccordionTrigger>
                      {translations.faq.section1.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section1.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-2">
                    <AccordionTrigger>
                      {translations.faq.section1.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section1.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-3">
                    <AccordionTrigger>
                      {translations.faq.section1.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section1.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-4">
                    <AccordionTrigger>
                      {translations.faq.section1.item4.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section1.item4.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-5">
                    <AccordionTrigger>
                      {translations.faq.section1.item5.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section1.item5.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-7-1">
              <AccordionTrigger variant="bold" icon={ShirtIcon}>
                {translations.faq.section2.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-7-2">
                    <AccordionTrigger>
                      {translations.faq.section2.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section2.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-5">
                    <AccordionTrigger>
                      {translations.faq.section2.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section2.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-3">
                    <AccordionTrigger>
                      {translations.faq.section2.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section2.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-4">
                    <AccordionTrigger>
                      {translations.faq.section2.item4.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section2.item4.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-6">
                    <AccordionTrigger>
                      {translations.faq.section2.item5.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section2.item5.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-7">
                    <AccordionTrigger>
                      {translations.faq.section2.item6.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section2.item6.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-8-1">
              <AccordionTrigger
                variant="bold"
                icon={ScreenprintThermotransferIcon}
              >
                {translations.faq.section3.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-8-2">
                    <AccordionTrigger>
                      {translations.faq.section3.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-3">
                    <AccordionTrigger>
                      {translations.faq.section3.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-10">
                    <AccordionTrigger>
                      {translations.faq.section3.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-11">
                    <AccordionTrigger>
                      {translations.faq.section3.item4.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item4.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-12">
                    <AccordionTrigger>
                      {translations.faq.section3.item5.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item5.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-4">
                    <AccordionTrigger>
                      {translations.faq.section3.item6.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item6.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-5">
                    <AccordionTrigger>
                      {translations.faq.section3.item7.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item7.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-6">
                    <AccordionTrigger>
                      {translations.faq.section3.item8.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item8.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-7">
                    <AccordionTrigger>
                      {translations.faq.section3.item9.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item9.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-8">
                    <AccordionTrigger>
                      {translations.faq.section3.item10.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item10.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-8-9">
                    <AccordionTrigger>
                      {translations.faq.section3.item11.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item11.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-9-8">
                    <AccordionTrigger>
                      {translations.faq.section3.item12.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section3.item12.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-2-1">
              <AccordionTrigger variant="bold" icon={DtfIcon}>
                {translations.faq.section4.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-2-2">
                    <AccordionTrigger>
                      {translations.faq.section4.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section4.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-3">
                    <AccordionTrigger>
                      {translations.faq.section4.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section4.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-4">
                    <AccordionTrigger>
                      {translations.faq.section4.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section4.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-6">
                    <AccordionTrigger>
                      {translations.faq.section4.item4.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section4.item4.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-8">
                    <AccordionTrigger>
                      {translations.faq.section4.item5.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section4.item5.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-7">
                    <AccordionTrigger>
                      {translations.faq.section4.item6.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section4.item6.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-5">
                    <AccordionTrigger>
                      {translations.faq.section4.item7.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section4.item7.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-9-1">
              <AccordionTrigger variant="bold" icon={ScreenprintIcon}>
                {translations.faq.section5.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-9-2">
                    <AccordionTrigger>
                      {translations.faq.section5.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section5.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-9-3">
                    <AccordionTrigger>
                      {translations.faq.section5.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section5.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-9-4">
                    <AccordionTrigger>
                      {translations.faq.section5.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section5.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-9-5">
                    <AccordionTrigger>
                      {translations.faq.section5.item4.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section5.item4.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-9-6">
                    <AccordionTrigger>
                      {translations.faq.section5.item5.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section5.item5.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-9-7">
                    <AccordionTrigger>
                      {translations.faq.section5.item6.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section5.item6.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-9-8">
                    <AccordionTrigger>
                      {translations.faq.section5.item7.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section5.item7.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-10-1">
              <AccordionTrigger variant="bold" icon={SublimationIcon}>
                {translations.faq.section6.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-10-2">
                    <AccordionTrigger>
                      {translations.faq.section6.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-3">
                    <AccordionTrigger>
                      {translations.faq.section6.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-4">
                    <AccordionTrigger>
                      {translations.faq.section6.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-9">
                    <AccordionTrigger>
                      {translations.faq.section6.item4.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item4.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-5">
                    <AccordionTrigger>
                      {translations.faq.section6.item5.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item5.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-6">
                    <AccordionTrigger>
                      {translations.faq.section6.item6.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item6.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-7">
                    <AccordionTrigger>
                      {translations.faq.section6.item7.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item7.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-8">
                    <AccordionTrigger>
                      {translations.faq.section6.item8.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item8.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-10-10">
                    <AccordionTrigger>
                      {translations.faq.section6.item9.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section6.item9.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-3-1">
              <AccordionTrigger variant="bold" icon={GraphicsIcon}>
                {translations.faq.section7.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-3-2">
                    <AccordionTrigger>
                      {translations.faq.section7.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section7.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-3">
                    <AccordionTrigger>
                      {translations.faq.section7.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section7.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-5">
                    <AccordionTrigger>
                      {translations.faq.section7.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section7.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-4-1">
              <AccordionTrigger variant="bold" icon={DeliveryIcon}>
                {translations.faq.section8.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-4-2">
                    <AccordionTrigger>
                      {translations.faq.section8.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section8.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-3">
                    <AccordionTrigger>
                      {translations.faq.section8.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section8.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-4">
                    <AccordionTrigger>
                      {translations.faq.section8.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section8.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-5">
                    <AccordionTrigger>
                      {translations.faq.section8.item4.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section8.item4.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-6">
                    <AccordionTrigger>
                      {translations.faq.section8.item5.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section8.item5.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-5-1">
              <AccordionTrigger variant="bold" icon={PaymentIcon}>
                {translations.faq.section9.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-5-2">
                    <AccordionTrigger>
                      {translations.faq.section9.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section9.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-3">
                    <AccordionTrigger>
                      {translations.faq.section9.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section9.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-4">
                    <AccordionTrigger>
                      {translations.faq.section9.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section9.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-6-1">
              <AccordionTrigger variant="bold" icon={RefundIcon}>
                {translations.faq.section10.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-6-2">
                    <AccordionTrigger>
                      {translations.faq.section10.item1.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section10.item1.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-6-3">
                    <AccordionTrigger>
                      {translations.faq.section10.item2.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section10.item2.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-6-4">
                    <AccordionTrigger>
                      {translations.faq.section10.item3.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.section10.item3.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
