import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import PageHeader from "../ui/custom/page_header";
import TextContainer from "../ui/custom/text_container";
import DOMpurify from "dompurify";
import rehypeRaw from "rehype-raw";
import rehypeSmoothScroll from "../../utils/rehypeSmoothScroll";
import { useTranslation } from "../../TranslationContext";

const TermsOfService = () => {
  const [markdown, setMarkdown] = useState("");
  const translations = useTranslation();
  const { termsOfService } = translations;

  useEffect(() => {
    fetch(termsOfService.markdownRoute)
      .then((response) => response.text())
      .then((text) => {
        const sanitizedMarkdown = DOMpurify.sanitize(text);
        setMarkdown(sanitizedMarkdown);
      })
      .catch((error) =>
        console.error("Error fetching terms of service markdown:", error)
      );
  }, []);

  return (
    <div>
      <PageHeader title={termsOfService.pageTitle} />
      <TextContainer>
        <p className="markdown">
          <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSmoothScroll]}>
            {markdown}
          </ReactMarkdown>
        </p>

        <p className="pt-20 pb-2 uppercase font-semibold">
          {termsOfService.previousVersions.title}
        </p>
        <p className="m-0">
          <a
            href={termsOfService.previousVersions.currentVersion.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {termsOfService.previousVersions.currentVersion.text}
          </a>
        </p>
      </TextContainer>
    </div>
  );
};

export default TermsOfService;
