import { FunctionComponent, useEffect, useState } from "react";

import fcke from "../../assets/svg/fcke.svg";
import abczoo from "../../assets/svg/abczoo.svg";
import kfa from "../../assets/svg/kfa.svg";
import marcocar from "../../assets/svg/marcocar.svg";
import jbl from "../../assets/svg/jbl.svg";
import roznava from "../../assets/svg/roznava.svg";
import aquareina from "../../assets/svg/aquareina.svg";
import atlke from "../../assets/svg/atlke.svg";
import urbanfest from "../../assets/svg/urbanfest.svg";
import tuke from "../../assets/svg/tuke.svg";

import { useTranslation } from "../../TranslationContext";

const Metrics: FunctionComponent = () => {
  const translations = useTranslation();
  const [badges, setBadges] = useState(100000);
  const [experience, setExperience] = useState(5);

  useEffect(() => {
    const startBadges = new Date(2023, 10, 1); // November 1, 2023
    const startExperience = new Date(2002, 10, 1); // November 1, 2002

    const updateMetrics = () => {
      const now = new Date();

      // Update experience
      if (now >= startExperience) {
        const yearsPassed = Math.floor(
          (now.getTime() - startExperience.getTime()) /
            (1000 * 60 * 60 * 24 * 365)
        );
        setExperience(yearsPassed);
      }
    };

    const intervalId = setInterval(updateMetrics, 1); // Update every second
    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);

  return (
    <div className="relative bg-whitew-full flex flex-col items-center justify-center">
      <div
        id="metrics"
        className="relative bg-slate-950 w-full flex flex-row py-16 px-16 items-center justify-center text-center text-5xl text-gray-200 font-text-xl-font-medium sm:text-4xl xs:px-6"
      >
        <div className="flex flex-row w-full max-w-[1340px] items-center justify-around lg:flex-col lg:gap-12">
          <div className="flex flex-col py-0 px-4 items-center justify-center w-[360px]">
            <div className="relative leading-[150%] font-extrabold text-gradient">
              {translations.metrics.metric_1.number}
            </div>
            <div className="relative leading-[150%] font-medium text-2xl sm:text-xl">
              {translations.metrics.metric_1.text}
            </div>
          </div>
          <div className="flex flex-col py-0 px-4 items-center justify-center w-[360px]">
            <div className="relative leading-[150%] font-extrabold text-gradient">
              {translations.metrics.metric_2.number}
            </div>
            <div className="relative leading-[150%] font-medium text-2xl sm:text-xl">
              {translations.metrics.metric_2.text}
            </div>
          </div>
          <div className="flex flex-col py-0 px-4 items-center justify-center w-[360px]">
            <div className="relative leading-[150%] font-extrabold text-gradient">
              {experience} +
            </div>
            <div className="relative leading-[150%] font-medium text-2xl sm:text-xl">
              {translations.metrics.metric_3.text}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-28 px-boundvariablesdata pb-24 sm:pb-16 box-border text-center text-4xl text-slate-900 sm:text-3xl xs:text-2xl">
        <div className="self-stretch flex flex-col items-center justify-start gap-14 sm:gap-14 xs:gap-8">
          <div className="w-full flex flex-col items-center justify-start max-w-[800px]">
            <div className="self-stretch relative leading-[150%] font-extrabold">
              {translations.metrics.references.title}
            </div>
          </div>
          <div className="w-full flex flex-row items-center justify-center gap-16 max-w-[1200px] xl:max-w-[1000px] px-10 sm:h-16 sm:gap-6 sm:px-6">
            <img
              className="w-[198.57px] relative h-[60px] overflow-hidden sm:w-fit overflow-hidden shrink"
              alt="abczoo"
              src={abczoo}
            />
            <img
              className="w-[219.9px] relative h-[60px] overflow-hidden shrink-0 xl:hidden"
              alt="marcocar"
              src={marcocar}
            />
            <img
              className="w-[107px] relative h-[60px] sm:max-h-[60px] sm:w-fit overflow-hidden shrink"
              alt="jbl"
              src={jbl}
            />
            <img
              className="w-[176.98px] relative h-[60.74px] sm:max-h-[60px] sm:w-fit overflow-hidden shrink lg:hidden"
              alt="tuke"
              src={tuke}
            />
            <img
              className="w-[102.02px] relative h-[108px] sm:max-h-[108px] sm:w-fit overflow-hidden shrink"
              alt="fcke"
              src={fcke}
            />
          </div>
          <div className="w-full flex flex-row items-center justify-center gap-16 max-w-[1200px] xl:max-w-[1000px] px-10 sm:gap-8 sm:px-6">
            <img
              className="w-[120px] relative h-[108px] overflow-hidden shrink-0 lg:hidden"
              alt="roznava"
              src={roznava}
            />
            <img
              className="w-[203.48] relative h-[60px] sm:max-h-[60px] sm:w-fit overflow-hidden shrink"
              alt="aquareina"
              src={aquareina}
            />
            <img
              className="w-[280px] relative h-[60px] sm:max-h-[60px] sm:w-fit overflow-hidden shrink"
              alt="kfa"
              src={kfa}
            />
            <img
              className="w-[106px] relative h-[93px] overflow-hidden shrink-0 md:hidden"
              alt="atlke"
              src={atlke}
            />
            <img
              className="w-[151.53px] relative h-[60px] overflow-hidden shrink-0 xl:hidden"
              alt="urbanfest"
              src={urbanfest}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
