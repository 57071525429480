import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "../../global.css";
import logo from "../../assets/svg/light-rectangle.svg";
import { useTranslation } from "../../TranslationContext";

const Footer: FunctionComponent = () => {
  const translations = useTranslation();
  const location = useLocation();

  return (
    <div
      id="footer"
      className="relative w-full flex flex-col items-center justify-center bg-white"
    >
      <footer className="self-stretch bg-slate-950 flex flex-col py-20 md:py-16 sm:pt-10 sm:pb-24 px-24 items-center justify-center gap-12 text-left text-2xl text-white font-text-base-font-medium lg:px-12 md:px-8 mt-24">
        <div className="w-full flex flex-row flex-wrap items-center md:items-start justify-center gap-2 max-w-[1440px] xl:flex-col lg:flex-col md:flex-row">
          <div className="h-[200px] w-auto sm:w-full flex flex-col py-10 px-10 box-border items-center justify-center sm:py-0 sm:px-0">
            <img
              className="relative w-[200px] h-[113.06px] overflow-hidden shrink-0 "
              alt=""
              src={logo}
            />
          </div>
          <div className="flex-1 flex flex-row items-start justify-end xl:justify-between sm:flex-col gap-10 2xl:gap-4 sm:grid-cols-1 xs:grid-cols-1 w-fit sm:px-4 md:px-6 xs:px-0">
            <div className="flex flex-col py-2 items-start justify-center xl:hidden gap-4 px-4 lg:px-4 md:px-0">
              <div className="relative leading-[150%] font-semibold">
                {translations.footer.section1.info}
              </div>
              <div className="flex flex-col items-start justify-center gap-2 text-base text-slate-300">
                {location.pathname === "/" ? (
                  <ScrollLink
                    to="pricing"
                    smooth={true}
                    duration={500}
                    offset={-88}
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer hidden"
                  >
                    {translations.footer.section1.pricing}
                  </ScrollLink>
                ) : (
                  <RouterLink
                    to="/#pricing"
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer hidden"
                  >
                    {translations.footer.section1.pricing}
                  </RouterLink>
                )}
                {location.pathname === "/" ? (
                  <ScrollLink
                    to="services"
                    smooth={true}
                    duration={500}
                    offset={-88}
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer"
                  >
                    {translations.footer.section1.services}
                  </ScrollLink>
                ) : (
                  <RouterLink
                    to="/#services"
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer"
                  >
                    {translations.footer.section1.services}
                  </RouterLink>
                )}
                {location.pathname === "/" ? (
                  <ScrollLink
                    to="testimonials"
                    smooth={true}
                    duration={500}
                    offset={-88}
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer hidden"
                  >
                    {translations.footer.section1.testimonials}
                  </ScrollLink>
                ) : (
                  <RouterLink
                    to="/#testimonials"
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer hidden"
                  >
                    {translations.footer.section1.testimonials}
                  </RouterLink>
                )}
                {location.pathname === "/" ? (
                  <ScrollLink
                    to="faq"
                    smooth={true}
                    duration={500}
                    offset={-88}
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer"
                  >
                    {translations.footer.section1.faq}
                  </ScrollLink>
                ) : (
                  <RouterLink
                    to="/#faq"
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer"
                  >
                    {translations.footer.section1.faq}
                  </RouterLink>
                )}
                <div className="relative leading-[150%]">
                  <Link
                    to="/contact"
                    className="[text-decoration:none] relative leading-[150%] text-[inherit]"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {translations.footer.section1.contact}
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-2 items-start justify-center gap-4 px-8 lg:px-4 md:px-0">
              <div className="relative leading-[150%] font-semibold">
                {translations.footer.section2.order}
              </div>
              <div className="flex flex-col items-start justify-center gap-[8px] text-base text-slate-300">
                <Link
                  to="/order"
                  className="[text-decoration:none] relative leading-[150%] text-[inherit]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.footer.section2.link1}
                </Link>
                {location.pathname === "/" ? (
                  <ScrollLink
                    to="graphic_preparation"
                    smooth={true}
                    duration={500}
                    offset={-88}
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer"
                  >
                    {translations.footer.section2.link2}
                  </ScrollLink>
                ) : (
                  <RouterLink
                    to="/#graphic_preparation"
                    className="[text-decoration:none] relative leading-[150%] text-[inherit] cursor-pointer"
                  >
                    {translations.footer.section2.link2}
                  </RouterLink>
                )}
                <Link
                  to="/delivery-policy"
                  className="[text-decoration:none] relative leading-[150%] text-[inherit]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.footer.section2.link3}
                </Link>
                <Link
                  to="/payment-policy"
                  className="[text-decoration:none] relative leading-[150%] text-[inherit]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.footer.section2.link4}
                </Link>
              </div>
            </div>
            <div className="flex flex-col items-start py-2 justify-center gap-4 px-8 lg:px-4 md:px-0">
              <div className="relative leading-[150%] font-semibold">
                {translations.footer.section3.title}
              </div>
              <div className="flex flex-col items-start justify-center gap-[8px] text-base text-slate-300">
                <div className="[text-decoration:none] relative leading-[150%] text-[inherit]">
                  <a
                    href="https://maps.app.goo.gl/nrbfaUsNsKpbrYaY9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="m-0">
                      {translations.footer.section3.address1}
                    </p>
                    <p className="m-0">
                      {translations.footer.section3.address2}
                    </p>
                  </a>
                </div>
                <div className="relative leading-[150%]">
                  <a href="mailto:info@nexprim.com">info@nexprim.com</a>
                </div>
                <div className="relative leading-[150%]">
                  <a href="tel:+421915558916">+421 915 721 721</a>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start py-2 justify-center gap-4 px-8 lg:px-4 md:px-0">
              <div className="relative leading-[150%] font-semibold">
                {translations.footer.section4.title}
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <a
                    className="relative w-6 h-6"
                    href={translations.footer.section4.instagramLink}
                  >
                    <svg width="24" height="24">
                      <path
                        d="M16.7804 1.854H7.22109C3.97059 1.854 1.33008 4.49451 1.33008 7.74501V17.3043C1.33008 20.5548 3.97059 23.1953 7.22109 23.1953H16.7804C20.0309 23.1953 22.6714 20.5548 22.6714 17.3043V7.74501C22.6714 4.49451 20.0309 1.854 16.7804 1.854ZM20.7746 17.3043C20.7746 19.5103 18.9864 21.2985 16.7804 21.2985H7.22109C5.01509 21.2985 3.2269 19.5103 3.2269 17.3043V7.74501C3.2269 5.53902 5.01509 3.75082 7.22109 3.75082H16.7804C18.9864 3.75082 20.7746 5.53902 20.7746 7.74501V17.3043Z"
                        fill="#CBD5E1"
                      />
                      <path
                        d="M12.0003 7.02637C8.96704 7.02637 6.50201 9.4914 6.50201 12.5246C6.50201 15.5579 8.96704 18.0229 12.0003 18.0229C15.0335 18.0229 17.4986 15.5579 17.4986 12.5246C17.4986 9.4914 15.0335 7.02637 12.0003 7.02637ZM12.0003 16.1261C10.0115 16.1261 8.39883 14.5134 8.39883 12.5246C8.39883 10.5359 10.0199 8.92319 12.0003 8.92319C13.9807 8.92319 15.6017 10.5443 15.6017 12.5246C15.6017 14.5134 13.9807 16.1261 12.0003 16.1261Z"
                        fill="#CBD5E1"
                      />
                      <path
                        d="M17.7328 5.42188C17.3651 5.42188 17.0058 5.57228 16.7551 5.83132C16.4961 6.09036 16.3457 6.44967 16.3457 6.80898C16.3457 7.16829 16.4961 7.53595 16.7551 7.78663C17.0142 8.04567 17.3735 8.19608 17.7328 8.19608C18.0921 8.19608 18.4598 8.04567 18.7105 7.78663C18.9695 7.5276 19.1199 7.16829 19.1199 6.80898C19.1199 6.44967 18.9695 6.082 18.7105 5.83132C18.4514 5.57228 18.0921 5.42188 17.7328 5.42188Z"
                        fill="#CBD5E1"
                      />
                    </svg>
                  </a>
                  <a
                    className="relative w-6 h-6"
                    href={translations.footer.section4.facebookLink}
                  >
                    <svg width="24" height="24">
                      <path
                        d="M9.60371 22.0238H13.4263V12.4458H16.0992L16.3866 9.24115H13.4335V7.41609C13.4335 6.66163 13.5844 6.35985 14.3173 6.35985H16.3866V3.02588H13.7353C10.8899 3.02588 9.60371 4.27612 9.60371 6.67601V9.23397H7.61339V12.4817H9.60371V22.0238Z"
                        fill="#CBD5E1"
                      />
                    </svg>
                  </a>
                  <a
                    className="relative w-6 h-6"
                    href={translations.footer.section4.linkedinLink}
                  >
                    <svg width="24" height="24">
                      <path
                        d="M7.01524 9.20361H3.27173C3.10461 9.20361 2.97092 9.33731 2.97092 9.50443V21.5204C2.97092 21.6875 3.10461 21.8212 3.27173 21.8212H7.01524C7.18236 21.8212 7.31605 21.6875 7.31605 21.5204V9.50443C7.31605 9.33731 7.18236 9.20361 7.01524 9.20361Z"
                        fill="#CBD5E1"
                      />
                      <path
                        d="M5.14298 3.229C3.78094 3.229 2.67795 4.332 2.67795 5.69404C2.67795 7.05607 3.7893 8.15907 5.14298 8.15907C6.49665 8.15907 7.60801 7.05607 7.60801 5.69404C7.60801 4.332 6.50501 3.229 5.14298 3.229Z"
                        fill="#CBD5E1"
                      />
                      <path
                        d="M16.5328 8.90283C15.0287 8.90283 13.9173 9.54625 13.2489 10.2816V9.50447C13.2489 9.33735 13.1152 9.20365 12.948 9.20365H9.3633C9.19618 9.20365 9.06248 9.33735 9.06248 9.50447V21.5204C9.06248 21.6876 9.19618 21.8213 9.3633 21.8213H13.0985C13.2656 21.8213 13.3993 21.6876 13.3993 21.5204V15.5709C13.3993 13.5655 13.9424 12.7884 15.3379 12.7884C16.8587 12.7884 16.984 14.0418 16.984 15.6796V21.5204C16.984 21.6876 17.1177 21.8213 17.2848 21.8213H21.02C21.1871 21.8213 21.3208 21.6876 21.3208 21.5204V14.9275C21.3208 11.9444 20.7526 8.90283 16.5411 8.90283H16.5328Z"
                        fill="#CBD5E1"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-6 max-w-[1440px] text-base text-slate-300">
          <div className="self-stretch relative box-border h-0.5 border-t-[2px] border-solid border-slate-700" />
          <div className="self-stretch flex flex-row items-start justify-between sm:flex-col-reverse">
            <a
              href="https://nexprim.com/"
              className="[text-decoration:none] relative leading-[150%] font-medium text-[inherit]"
            >
              {translations.footer.section5.nexprim}
            </a>
            <div className="flex flex-row items-start justify-start gap-4 text-right sm:flex-col sm:mb-6">
              <Link
                to="/privacy-policy"
                className="[text-decoration:none] relative leading-[150%] font-medium text-[inherit]"
                onClick={() => window.scrollTo(0, 0)}
              >
                {translations.footer.section5.link1}
              </Link>
              <Link
                to="/terms-of-service"
                className="[text-decoration:none] relative leading-[150%] font-medium text-[inherit]"
                onClick={() => window.scrollTo(0, 0)}
              >
                {translations.footer.section5.link2}
              </Link>
              <Link
                to="/cookies"
                className="[text-decoration:none] relative leading-[150%] font-medium text-[inherit]"
                onClick={() => window.scrollTo(0, 0)}
              >
                {translations.footer.section5.link3}
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
