import { visit } from 'unist-util-visit';

interface HTMLElementWithOnClick extends HTMLElement {
  onClick?: (e: MouseEvent) => void;
}

const rehypeSmoothScroll = () => {
  return (tree: any) => {
    visit(tree, 'element', (node: any) => {
      if (node.tagName === 'a' && node.properties?.href?.toString().startsWith('#')) {
        const originalProperties = node.properties;
        node.properties = {
          ...originalProperties,
          onClick: (e: MouseEvent) => {
            e.preventDefault();
            const targetId = originalProperties.href.toString().substring(1);
            const targetElement = document.getElementById(targetId) as HTMLElementWithOnClick | null;
            if (targetElement) {
              const top = targetElement.getBoundingClientRect().top + window.pageYOffset - 100; // Adjust 100px offset as needed
              window.scrollTo({ top, behavior: 'smooth' });
            }
          },
        };
      }
    });
  };
};

export default rehypeSmoothScroll;