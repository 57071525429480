import { useState, useEffect } from "react"; // Import useEffect here
import { FunctionComponent } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "../../TranslationContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/custom/select_language";

import { ReactComponent as SK } from "../../assets/svg/SK.svg";
import { ReactComponent as CZ } from "../../assets/svg/CZ.svg";
import { ReactComponent as DE } from "../../assets/svg/DE.svg";
import { ReactComponent as EU } from "../../assets/svg/EU.svg";

const TopBar: FunctionComponent = () => {
  const translations = useTranslation();

  const getDefaultLanguage = () => {
    const hostname = window.location.hostname;
    if (hostname === "odznaky.nexprim.sk") {
      return "SK";
    } else if (hostname === "badges.nexprim.com") {
      const browserLang = navigator.language.slice(0, 2).toUpperCase();
      const supportedLangs = ["EN", "CZ", "DE", "SK"];
      return supportedLangs.includes(browserLang) ? browserLang : "EN";
    }
    return "SK"; // Default to Slovak if hostname doesn't match
  };

  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("lang") || getDefaultLanguage()
  );

  useEffect(() => {
    const currentLang = Cookies.get("lang");
    if (currentLang !== selectedLanguage) {
      Cookies.set("lang", selectedLanguage, { expires: 365 });
      window.location.href = window.location.pathname; // Redirect to the same page
    }
  }, [selectedLanguage]);

  const getIcon = (language: string) => {
    switch (language) {
      case "EN":
        return <EU className="w-8 h-auto" />;
      case "CZ":
        return <CZ className="w-8 h-auto" />;
      case "DE":
        return <DE className="w-8 h-auto" />;
      case "SK":
        return <SK className="w-8 h-auto" />;
      default:
        return <SK className="w-8 h-auto" />;
    }
  };

  const getLanguageLabel = (language: string) => {
    switch (language) {
      case "EN":
        return "EN";
      case "CZ":
        return "CZ";
      case "DE":
        return "DE";
      case "SK":
        return "SK";
      default:
        return "SK";
    }
  };
  return (
    <div
      id="topbar"
      className="relative bg-slate-950 w-full flex flex-row py-2 px-14 box-border items-center justify-center text-center text-base text-white font-text-base-font-normal sm:px-8 xs:px-4 xs:text-sm"
    >
      <div className="[text-decoration:none] flex-1 flex flex-row flex-wrap items-center justify-center gap-[3px] text-[inherit] max-w-[1440px] ">
        <div className="[text-decoration:none] flex-1 flex flex-row flex-wrap items-center justify-center gap-[3px] text-[inherit]">
          <div className="relative leading-[150%]">
            <span className="inline sm:hidden">
              {translations.topbar.info.text1}
            </span>
            <a
              href={translations.topbar.info.link}
              className="text-gradient relative leading-[150%] font-bold uppercase underline decoration-fuchsia-500"
            >
              {translations.topbar.info.highlight1}
            </a>
            {translations.topbar.info.text2}
          </div>
        </div>

        <div className="self-stretch flex flex-row relative items-center z-60">
          <div
            className="relative"
            onClick={(e) => e.stopPropagation()} // Stop event propagation at the wrapper level
            // style={{ zIndex: 1000 }} // Ensure the wrapper has a high z-index
          >
            <Select
              name="language"
              autoComplete="language"
              defaultValue={selectedLanguage}
              onValueChange={setSelectedLanguage}
            >
              <SelectTrigger className="flex items-center justify-between bg-gray-800 text-white rounded-lg px-4 py-2 shadow-lg border-0 w-full">
                <div className="flex items-center space-x-2">
                  {getIcon(selectedLanguage)}
                  <span className="font-medium pr-2 sm:hidden">
                    {getLanguageLabel(selectedLanguage)}
                  </span>
                </div>
              </SelectTrigger>
              <SelectContent
                className="bg-gray-900 text-white rounded-lg shadow-lg border-0 w-full"
                style={{ pointerEvents: "auto" }} // Ensure pointer events are enabled
              >
                <SelectGroup>
                  <SelectItem
                    value="SK"
                    className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                  >
                    <SK className="w-8 h-auto mr-2 " />{" "}
                    <span className="inline sm:hidden">Slovenčina</span>
                  </SelectItem>
                  <SelectItem
                    value="CZ"
                    className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                  >
                    <CZ className="w-8 h-auto mr-2" />{" "}
                    <span className="inline sm:hidden">Čeština</span>
                  </SelectItem>
                  <SelectItem
                    value="EN"
                    className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                  >
                    <EU className="w-8 h-auto mr-2" />{" "}
                    <span className="inline sm:hidden">English</span>
                  </SelectItem>
                  <SelectItem
                    value="DE"
                    className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                  >
                    <DE className="w-8 h-auto mr-2" />{" "}
                    <span className="inline sm:hidden">Deutsch</span>
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
