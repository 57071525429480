import React from "react";

const PaymentIcon: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
      fill="url(#paint0_linear_453_116948)"
    />
    <g clipPath="url(#clip0_453_116948)">
      <mask
        id="mask0_453_116948"
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="24"
        height="24"
      >
        <path d="M12 12H36V36H12V12Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_453_116948)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 18C17.4696 18 16.9609 18.2107 16.5858 18.5858C16.2107 18.9609 16 19.4696 16 20V21H32V20C32 19.4696 31.7893 18.9609 31.4142 18.5858C31.0391 18.2107 30.5304 18 30 18H18ZM34 20C34 18.9391 33.5786 17.9217 32.8284 17.1716C32.0783 16.4214 31.0609 16 30 16H18C16.9391 16 15.9217 16.4214 15.1716 17.1716C14.4214 17.9217 14 18.9391 14 20V28C14 29.0609 14.4214 30.0783 15.1716 30.8284C15.9217 31.5786 16.9391 32 18 32H30C31.0609 32 32.0783 31.5786 32.8284 30.8284C33.5786 30.0783 34 29.0609 34 28V20ZM32 23H16V28C16 28.5304 16.2107 29.0391 16.5858 29.4142C16.9609 29.7893 17.4696 30 18 30H30C30.5304 30 31.0391 29.7893 31.4142 29.4142C31.7893 29.0391 32 28.5304 32 28V23ZM18 27C18 26.4477 18.4477 26 19 26H19.01C19.5623 26 20.01 26.4477 20.01 27C20.01 27.5523 19.5623 28 19.01 28H19C18.4477 28 18 27.5523 18 27ZM22 27C22 26.4477 22.4477 26 23 26H25C25.5523 26 26 26.4477 26 27C26 27.5523 25.5523 28 25 28H23C22.4477 28 22 27.5523 22 27Z"
          fill="url(#paint1_linear_453_116948)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_453_116948"
        x1="0"
        y1="0"
        x2="57.7461"
        y2="27.6332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBFAFC" />
        <stop offset="0.354167" stopColor="#FFF4FF" />
        <stop offset="0.677083" stopColor="#FEE6FF" />
        <stop offset="0.911458" stopColor="#FEF1FF" />
        <stop offset="1" stopColor="#FCF7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_453_116948"
        x1="14"
        y1="16"
        x2="35.7783"
        y2="29.027"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stopColor="#A554E5" />
        <stop offset="0.666667" stopColor="#FF00D6" />
        <stop offset="1" stopColor="#A554E5" />
      </linearGradient>
      <clipPath id="clip0_453_116948">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PaymentIcon;
