import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "../ui/button";
import screenprint from "../../assets/svg/screenprint.svg";
import screenprint_thermotransfer from "../../assets/svg/screenprint_thermotransfer.svg";
import sublimation from "../../assets/svg/sublimation.svg";
import dtf from "../../assets/svg/dtf.svg";
import stonks from "../../assets/svg/stonks.svg";
import { useTranslation } from "../../TranslationContext";

const GraphicPreparation: FunctionComponent = () => {
  const translations = useTranslation();
  return (
    <div
      id="graphic_preparation"
      className="relative bg-white w-full overflow-hidden flex flex-col py-16 px-10 box-border items-center justify-start text-center text-4xl xs:text-2xl text-slate-900 font-normal md:px-8 xs:px-4"
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-10">
        <div className="w-full flex flex-col items-center justify-start gap-8 max-w-[1200px]">
          <div className="w-full flex flex-col items-center justify-start gap-4 max-w-[800px]">
            <div className="self-stretch relative leading-[150%] font-extrabold">
            {translations.technologies.title}
            </div>
            <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-500">
            {translations.technologies.subtitle}
            </div>
          </div>
          <div className="relative grid grid-cols-4 lg:grid-cols-2 sm:grid-cols-1 w-full lg:max-w-[640px] justify-items-center gap-10 sm:gap-4 text-left text-xl sm:text-lg xs:text-base text-slate-950 font-leading-tight font-normal py-4">
            <div className="w-fit relative rounded-2xl bg-white h-[450px] sm:h-fit w-[240px] lg:w-[280px] overflow-hidden flex flex-col items-center justify-between pb-6 pt-4 px-6 box-border text-left text-xl text-black shadow">
              <div className="self-stretch flex flex-col items-start justify-start gap-2">
                <img
                  className="w-[123.3px] relative h-[123.3px]"
                  alt=""
                  src={screenprint_thermotransfer}
                />
                <div className="self-stretch flex flex-col items-center justify-start gap-2">
                  <div className="self-stretch relative leading-[150%] font-medium">
                  {translations.technologies.technology_1.title}
                  </div>
                  <div className="self-stretch relative text-sm leading-[150%] text-slate-800 sm:pb-6">
                  {translations.technologies.technology_1.description}
                  </div>
                </div>
              </div>
              <RouterLink to="/screenprint_thermotransfer">
                <Button
                  variant="primary"
                  size="xl"
                  className="w-[200px]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.technologies.technology_1.buttonText}
                </Button>
              </RouterLink>
            </div>
            <div className="w-fit relative rounded-2xl bg-white h-[450px] sm:h-fit w-[240px] lg:w-[280px] overflow-hidden flex flex-col items-center justify-between pb-6 pt-4 px-6 box-border text-left text-xl text-black shadow">
              <div className="self-stretch flex flex-col items-start justify-start gap-2">
                <img
                  className="w-[123.3px] relative h-[123.3px]"
                  alt=""
                  src={dtf}
                />
                <div className="self-stretch flex flex-col items-center justify-start gap-2">
                  <div className="self-stretch relative leading-[150%] font-medium">
                  {translations.technologies.technology_2.title}
                  </div>
                  <div className="self-stretch relative text-sm leading-[150%] text-slate-800 sm:pb-6">
                  {translations.technologies.technology_2.description}
                  </div>
                </div>
              </div>
              <RouterLink to="/dtf">
                <Button
                  variant="primary"
                  size="xl"
                  className="w-[200px]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.technologies.technology_2.buttonText}
                </Button>
              </RouterLink>
            </div>
            <div className="w-fit relative rounded-2xl bg-white  h-[450px] sm:h-fit w-[240px] lg:w-[280px] overflow-hidden flex flex-col items-center justify-between pb-6 pt-4 px-6 box-border text-left text-xl text-black shadow">
              <div className="self-stretch flex flex-col items-start justify-start gap-2">
                <img
                  className="w-[123.3px] relative h-[123.3px]"
                  alt=""
                  src={screenprint}
                />
                <div className="self-stretch flex flex-col items-center justify-start gap-2">
                  <div className="self-stretch relative leading-[150%] font-medium">
                  {translations.technologies.technology_3.title}
                  </div>
                  <div className="self-stretch relative text-sm leading-[150%] text-slate-800 sm:pb-6">
                  {translations.technologies.technology_3.description}
                  </div>
                </div>
              </div>
              <RouterLink to="/screenprint">
                <Button
                  variant="primary"
                  size="xl"
                  className="w-[200px]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.technologies.technology_3.buttonText}
                </Button>
              </RouterLink>
            </div>
            <div className="w-fit relative rounded-2xl bg-white  h-[450px] sm:h-fit w-[240px] lg:w-[280px] overflow-hidden flex flex-col items-center justify-between pb-6 pt-4 px-6 box-border text-left text-xl text-black shadow">
              <div className="self-stretch flex flex-col items-start justify-start gap-2">
                <img
                  className="w-[123.3px] relative h-[123.3px]"
                  alt=""
                  src={sublimation}
                />
                <div className="self-stretch flex flex-col items-center justify-start gap-2">
                  <div className="self-stretch relative leading-[150%] font-medium">
                  {translations.technologies.technology_4.title}
                  </div>
                  <div className="self-stretch relative text-sm leading-[150%] text-slate-800 sm:pb-6">
                  {translations.technologies.technology_4.description}
                  </div>
                </div>
              </div>
              <RouterLink to="/sublimation">
                <Button
                  variant="primary"
                  size="xl"
                  className="w-[200px]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.technologies.technology_4.buttonText}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center py-28 px-12 md:px-6 box-border text-center text-4xl sm:text-2xl text-slate-900 font-text-lg-font-normal">
        <div className="self-stretch flex flex-col items-center justify-center gap-14 sm:gap-2">
          <div className="w-full flex flex-col items-center justify-center max-w-[800px]">
            <div className="self-stretch relative leading-[150%] font-extrabold">
              <p className="m-0">{translations.technologies.advantages.title_part_1}</p>
              <p className="m-0">{translations.technologies.advantages.title_part_2}</p>
            </div>
          </div>
          <div className="w-full flex flex-row sm:flex-col items-center justify-center gap-8 sm:gap-4 max-w-[1200px] text-left text-lg sm:text-base text-slate-900">
            <div className="self-stretch flex-1 flex flex-col items-center justify-center">
              <img
                className="w-[400px] relative h-[300px] sm:max-h-[200px]"
                alt=""
                src={stonks}
              />
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-8 sm:px-6 xs:px-0">
              <div className="self-stretch relative leading-[150%]">
              {translations.technologies.advantages.description}
              </div>
              <RouterLink to="/order">
                <Button
                  variant="default"
                  size="xl"
                  className="uppercase w-[200px]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.technologies.advantages.buttonText}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphicPreparation;
