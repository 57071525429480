import React, { ReactNode } from 'react';

interface TextContainerProps {
  children: ReactNode;
}

const TextContainer: React.FC<TextContainerProps> = ({ children }) => {
  return (
    <div className="relative bg-white w-full flex flex-col p-24 box-border items-center justify-start text-left text-inherit text-black font-inherit sm:px-14 xs:px-6">
      <div className="w-full flex flex-row items-center justify-start max-w-[1200px]">
        <div className="w-full flex-1 flex flex-row items-start justify-start max-w-[800px]">
          <div className="w-full flex-1 relative leading-[16px]">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextContainer;