import React, { createContext, useContext, ReactNode } from "react";

interface TranslationProviderProps {
  translations: { [key: string]: any };
  children: ReactNode;
}

const TranslationContext = createContext<{ [key: string]: any }>({});

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  translations,
  children,
}) => {
  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};
