import React from "react";

const RefundIcon: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
      fill="url(#paint0_linear_453_117193)"
    />
    <g clipPath="url(#clip0_453_117193)">
      <mask
        id="mask0_453_117193"
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="24"
        height="24"
      >
        <path d="M12 12H36V36H12V12Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_453_117193)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 16C18.7348 16 18.4804 16.1054 18.2929 16.2929C18.1054 16.4804 18 16.7348 18 17V31.1315L19.4453 30.168C19.8419 29.9035 20.37 29.9558 20.7071 30.2929L22 31.5858L23.2929 30.2929C23.6834 29.9024 24.3166 29.9024 24.7071 30.2929L26 31.5858L27.2929 30.2929C27.63 29.9558 28.1581 29.9035 28.5547 30.168L30 31.1315V17C30 16.7348 29.8946 16.4804 29.7071 16.2929C29.5196 16.1054 29.2652 16 29 16H19ZM16.8787 14.8787C17.4413 14.3161 18.2044 14 19 14H29C29.7956 14 30.5587 14.3161 31.1213 14.8787C31.6839 15.4413 32 16.2044 32 17V33C32 33.3688 31.797 33.7077 31.4719 33.8817C31.1467 34.0557 30.7522 34.0366 30.4453 33.8321L28.1274 32.2868L26.7071 33.7071C26.3166 34.0976 25.6834 34.0976 25.2929 33.7071L24 32.4142L22.7071 33.7071C22.3166 34.0976 21.6834 34.0976 21.2929 33.7071L19.8726 32.2868L17.5547 33.8321C17.2478 34.0366 16.8533 34.0557 16.5281 33.8817C16.203 33.7077 16 33.3688 16 33V17C16 16.2044 16.3161 15.4413 16.8787 14.8787ZM23.7071 19.2929C24.0976 19.6834 24.0976 20.3166 23.7071 20.7071L23.4142 21H25C25.7957 21 26.5587 21.3161 27.1213 21.8787C27.6839 22.4413 28 23.2044 28 24V26C28 26.5523 27.5523 27 27 27C26.4477 27 26 26.5523 26 26V24C26 23.7348 25.8946 23.4804 25.7071 23.2929C25.5196 23.1054 25.2652 23 25 23H23.4142L23.7071 23.2929C24.0976 23.6834 24.0976 24.3166 23.7071 24.7071C23.3166 25.0976 22.6834 25.0976 22.2929 24.7071L20.2929 22.7071C19.9024 22.3166 19.9024 21.6834 20.2929 21.2929L22.2929 19.2929C22.6834 18.9024 23.3166 18.9024 23.7071 19.2929Z"
          fill="url(#paint1_linear_453_117193)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_453_117193"
        x1="0"
        y1="0"
        x2="57.7461"
        y2="27.6332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBFAFC" />
        <stop offset="0.354167" stopColor="#FFF4FF" />
        <stop offset="0.677083" stopColor="#FEE6FF" />
        <stop offset="0.911458" stopColor="#FEF1FF" />
        <stop offset="1" stopColor="#FCF7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_453_117193"
        x1="16"
        y1="14"
        x2="36.6327"
        y2="21.8987"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stopColor="#A554E5" />
        <stop offset="0.666667" stopColor="#FF00D6" />
        <stop offset="1" stopColor="#A554E5" />
      </linearGradient>
      <clipPath id="clip0_453_117193">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RefundIcon;
