import React from "react";

const ScreenprintThermotransferIcon: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
      fill="url(#paint0_linear_1308_45441)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.29 16.29C20.48 16.1 20.73 16 21 16H31C31.27 16 31.52 16.11 31.71 16.29C31.9 16.48 32 16.73 32 17V27C32 27.27 31.89 27.52 31.71 27.71C31.53 27.9 31.27 28 31 28H21C20.73 28 20.48 27.89 20.29 27.71C20.1 27.52 20 27.27 20 27V17C20 16.73 20.11 16.48 20.29 16.29ZM18 18V17C18 16.2 18.32 15.44 18.88 14.88C19.44 14.32 20.21 14 21 14H31C31.8 14 32.56 14.32 33.12 14.88C33.68 15.44 34 16.21 34 17V27C34 27.8 33.68 28.56 33.12 29.12C32.56 29.68 31.79 30 31 30H30V31C30 31.8 29.68 32.56 29.12 33.12C28.56 33.68 27.79 34 27 34H17C16.2 34 15.44 33.68 14.88 33.12C14.32 32.56 14 31.79 14 31V21C14 20.2 14.32 19.44 14.88 18.88C15.44 18.32 16.21 18 17 18H18ZM28 30V31C28 31.27 27.89 31.52 27.71 31.71C27.53 31.9 27.27 32 27 32H17C16.73 32 16.48 31.89 16.29 31.71C16.1 31.52 16 31.27 16 31V21C16 20.73 16.11 20.48 16.29 20.29C16.48 20.1 16.73 20 17 20H18V27C18 27.8 18.32 28.56 18.88 29.12C19.44 29.68 20.21 30 21 30H28Z"
      fill="url(#paint1_linear_1308_45441)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1308_45441"
        x1="0"
        y1="0"
        x2="57.7461"
        y2="27.6332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBFAFC" />
        <stop offset="0.354167" stop-color="#FFF4FF" />
        <stop offset="0.677083" stop-color="#FEE6FF" />
        <stop offset="0.911458" stop-color="#FEF1FF" />
        <stop offset="1" stop-color="#FCF7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1308_45441"
        x1="14"
        y1="14"
        x2="38.0609"
        y2="25.5138"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#A554E5" />
        <stop offset="0.666667" stop-color="#FF00D6" />
        <stop offset="1" stop-color="#A554E5" />
      </linearGradient>
    </defs>
  </svg>
);

export default ScreenprintThermotransferIcon;
