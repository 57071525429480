import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "src/lib/utils";
import { Button } from "../ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { useTranslation } from "../../TranslationContext";

import PageHeader from "../ui/custom/page_header";
import check from "../../assets/svg/check.svg";
import close from "../../assets/svg/close.svg";
import stars_1 from "../../assets/svg/stars_1.svg";
import stars_2 from "../../assets/svg/stars_2.svg";
import stars_3 from "../../assets/svg/stars_3.svg";
import stars_4 from "../../assets/svg/stars_4.svg";
import stars_5 from "../../assets/svg/stars_5.svg";
import screenprint_transfer from "../../assets/png/screenprint_transfer_2.png";
import dtf_3 from "../../assets/webp/transfer_1.webp";
import dtf_4 from "../../assets/webp/transfer_2.webp";
import dtf_6 from "../../assets/webp/transfer_5.webp";

import FAQ from "../sections/faq";

const ScreenprintThermotransfer = () => {
  const translations = useTranslation();

  const [plastisolPesVisible, setPlastisolPesVisible] = React.useState(true);
  const [plastisolTpuVisible, setPlastisolTpuVisible] = React.useState(true);
  const [waterTpuVisible, setWaterTpuVisible] = React.useState(true);
  const [waterPaVisible, setWaterPaVisible] = React.useState(true);
  const [water3DVisible, setWater3DVisible] = React.useState(true);
  const [siliconeVisible, setSiliconeVisible] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  // Change to use an array for storing multiple values
  const [value, setValue] = React.useState<string[]>([]);

  const textile_types = [
    {
      value: translations.screenprintThermotransfer.textiles.tshirt,
      label: translations.screenprintThermotransfer.textiles.tshirt,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_tpu",
        "water_pa",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.sportTshirt,
      label: translations.screenprintThermotransfer.textiles.sportTshirt,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_tpu",
        "water_pa",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.jersey,
      label: translations.screenprintThermotransfer.textiles.jersey,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.polo,
      label: translations.screenprintThermotransfer.textiles.polo,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_tpu",
        "water_pa",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.hoodie,
      label: translations.screenprintThermotransfer.textiles.hoodie,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_tpu",
        "water_pa",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.fleece,
      label: translations.screenprintThermotransfer.textiles.fleece,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.elastic,
      label: translations.screenprintThermotransfer.textiles.elastic,
      suitableTransfers: ["plastisol_tpu", "water_tpu", "water_3D", "silicone"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.softshell,
      label: translations.screenprintThermotransfer.textiles.softshell,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.windbreaker,
      label: translations.screenprintThermotransfer.textiles.windbreaker,
      suitableTransfers: ["plastisol_pes", "water_3D", "silicone"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.winterJacket,
      label: translations.screenprintThermotransfer.textiles.winterJacket,
      suitableTransfers: ["plastisol_pes", "water_3D"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.workJacket,
      label: translations.screenprintThermotransfer.textiles.workJacket,
      suitableTransfers: ["plastisol_pes", "water_pa", "water_3D", "silicone"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.workPants,
      label: translations.screenprintThermotransfer.textiles.workPants,
      suitableTransfers: ["plastisol_pes", "water_pa", "water_3D", "silicone"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.sweatpants,
      label: translations.screenprintThermotransfer.textiles.sweatpants,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_tpu",
        "water_pa",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.reflectiveVest,
      label: translations.screenprintThermotransfer.textiles.reflectiveVest,
      suitableTransfers: ["plastisol_pes", "plastisol_tpu"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.reflectiveJacket,
      label: translations.screenprintThermotransfer.textiles.reflectiveJacket,
      suitableTransfers: ["plastisol_pes", "silicone"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.cap,
      label: translations.screenprintThermotransfer.textiles.cap,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_3D",
        "water_pa",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.cottonBag,
      label: translations.screenprintThermotransfer.textiles.cottonBag,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_tpu",
        "water_pa",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.pesBag,
      label: translations.screenprintThermotransfer.textiles.pesBag,
      suitableTransfers: [
        "plastisol_pes",
        "plastisol_tpu",
        "water_tpu",
        "water_pa",
        "water_3D",
        "silicone",
      ],
    },
    {
      value: translations.screenprintThermotransfer.textiles.paperBag,
      label: translations.screenprintThermotransfer.textiles.paperBag,
      suitableTransfers: ["plastisol_pes", "plastisol_tpu"],
    },
    {
      value: translations.screenprintThermotransfer.textiles.ppBag,
      label: translations.screenprintThermotransfer.textiles.ppBag,
      suitableTransfers: ["plastisol_pes", "plastisol_tpu"],
    },
  ];

  React.useEffect(() => {
    // Initialize all visibility states to false
    setPlastisolPesVisible(false);
    setPlastisolTpuVisible(false);
    setWaterTpuVisible(false);
    setWaterPaVisible(false);
    setWater3DVisible(false);
    setSiliconeVisible(false);
    // Initialize other visibility states to false similarly

    if (value.length === 0) {
      // If no textiles are selected, no transfers should be visible
      setPlastisolPesVisible(true);
      setPlastisolTpuVisible(true);
      setWaterTpuVisible(true);
      setWaterPaVisible(true);
      setWater3DVisible(true);
      setSiliconeVisible(true);
      return;
    }

    // Find suitable transfers for each selected textile
    const suitableTransfersLists = value.map((selectedValue) => {
      const textile = textile_types.find((t) => t.value === selectedValue);
      return textile ? textile.suitableTransfers : [];
    });

    // Calculate intersection of all suitable transfers lists
    const commonSuitableTransfers = suitableTransfersLists.reduce((a, b) =>
      a.filter((c) => b.includes(c))
    );

    // Update visibility based on commonSuitableTransfers
    setPlastisolPesVisible(commonSuitableTransfers.includes("plastisol_pes"));
    setPlastisolTpuVisible(commonSuitableTransfers.includes("plastisol_tpu"));
    setWaterTpuVisible(commonSuitableTransfers.includes("water_tpu"));
    setWaterPaVisible(commonSuitableTransfers.includes("water_pa"));
    setWater3DVisible(commonSuitableTransfers.includes("water_3D"));
    setSiliconeVisible(commonSuitableTransfers.includes("silicone"));

    // Update other visibility states similarly
  }, [value]); // This effect depends on the selected textile types

  // Function to handle adding/removing values from the selection
  const handleSelect = (currentValue: string) => {
    if (value.includes(currentValue)) {
      setValue(value.filter((val) => val !== currentValue));
    } else {
      setValue([...value, currentValue]);
    }
  };

  return (
    <div>
      <PageHeader
        title={translations.screenprintThermotransfer.pageHeader.title}
        subtitle={translations.screenprintThermotransfer.pageHeader.subtitle}
        background="default"
      />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-24 pb-16 px-20 md:px-10 box-border text-left text-4xl lg:text-3xl xs:text-2xl text-slate-900 font-text-lg-font-normal">
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="w-full flex flex-row md:flex-col-reverse items-center justify-center max-w-[1200px]">
            <div className="w-full md:max-w-[400px] self-stretch flex flex-col items-center justify-center px-10">
              <img
                className="w-full min-w-[320px] relative h-fit overflow-hidden shrink-0"
                alt=""
                src={screenprint_transfer}
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-6">
              <b className="self-stretch relative leading-[150%]">
                <h1>
                  {
                    translations.screenprintThermotransfer.descriptionSection
                      .title
                  }
                </h1>
              </b>
              <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-500">
                <p className="m-0">
                  {
                    translations.screenprintThermotransfer.descriptionSection
                      .description1
                  }
                </p>
                <br />
                <p className="m-0">
                  {
                    translations.screenprintThermotransfer.descriptionSection
                      .description2
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-slate-950 overflow-hidden flex flex-row items-start justify-center pt-16 pb-24 px-24 box-border text-center text-3xl text-white font-text-lg-font-normal">
        <div className="flex-1 flex flex-col items-center justify-start gap-14 max-w-[1400px]">
          <div className="w-full flex flex-col items-center justify-start gap-4 max-w-[800px]">
            <div className="self-stretch flex flex-col items-center justify-start gap-1">
              <div className="self-auto relative leading-[150%] font-medium text-gradient">
                {translations.screenprintThermotransfer.heatpressSection.title}
              </div>
              <div className="self-stretch relative text-4xl sm:text-3xl leading-[150%] font-extrabold">
                {
                  translations.screenprintThermotransfer.heatpressSection
                    .callForAction
                }
              </div>
            </div>
            <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-300">
              {
                translations.screenprintThermotransfer.heatpressSection
                  .description
              }
            </div>
          </div>
          <div className="flex flex-row items-center justify-center px-9">
            <RouterLink to="/order">
              <Button
                variant="default"
                size="xl"
                className="uppercase w-[200px]"
                onClick={() => window.scrollTo(0, 0)}
              >
                {translations.screenprintThermotransfer.heatpressSection.button}
              </Button>
            </RouterLink>
          </div>
          <div className="w-full self-stretch flex flex-col items-center justify-center">
            <div className="w-full self-stretch flex flex-row md:flex-col items-center justify-center gap-6">
              <img
                className="w-full max-w-[451.56px] sm:max-w-[300px] relative h-fit overflow-hidden shrink"
                alt=""
                src={dtf_3}
              />
              <img
                className="w-full max-w-[451.56px] sm:max-w-[300px] relative h-fit overflow-hidden shrink"
                alt=""
                src={dtf_6}
              />
              <img
                className="w-full max-w-[451.56px] relative h-fit overflow-hidden shrink lg:hidden"
                alt=""
                src={dtf_4}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-white flex flex-col items-center justify-center pt-20 px-20 md:px-10 sm:px-6 pb-24 box-border gap-16 text-center text-4xl lg:textl-3xl xs:text-2xl text-slate-900">
        <div className="w-full flex flex-col items-center justify-start max-w-[800px]">
          <b className="self-stretch relative leading-[150%]">
            {translations.screenprintThermotransfer.prosAndCons.title}
          </b>
        </div>
        <div className="w-full flex flex-row md:flex-col items-start justify-center gap-10 max-w-[1080px] text-left text-xl md:text-lg text-slate-950">
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .speed
                  }
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .speedDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .repeatability
                  }
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .repeatabilityDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .price
                  }
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .priceDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .effects
                  }
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprintThermotransfer.prosAndCons.pros
                      .effectsDescription
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {
                    translations.screenprintThermotransfer.prosAndCons.cons
                      .touch
                  }
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprintThermotransfer.prosAndCons.cons
                      .touchDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {
                    translations.screenprintThermotransfer.prosAndCons.cons
                      .texture
                  }
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprintThermotransfer.prosAndCons.cons
                      .textureDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {
                    translations.screenprintThermotransfer.prosAndCons.cons
                      .ironing
                  }
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprintThermotransfer.prosAndCons.cons
                      .ironingDescription
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-slate-950 flex flex-col items-center justify-center py-24 px-20 md:py-10 box-border gap-10 text-left text-4xl lg:textl-3xl xs:text-2xl text-white sm:px-6">
        <div className="w-full flex flex-col items-center justify-start gap-[16px_0px] max-w-[800px] text-center">
          <b className="self-stretch relative leading-[150%]">
            {translations.screenprintThermotransfer.comparison.title}
          </b>
          <div className="self-stretch relative text-lg leading-[150%] text-slate-300">
            {translations.screenprintThermotransfer.comparison.description}
          </div>
        </div>
        <div className="w-fit flex flex-col items-start justify-start gap-2 text-sm text-slate-800">
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="default"
                role="combobox"
                aria-expanded={open}
                className="w-[200px] justify-between"
              >
                {value.length > 0
                  ? `${value.length} typ${
                      value.length > 4 ? "ov" : value.length > 1 ? "y" : ""
                    } zvolen${
                      value.length > 4 ? "ých" : value.length > 1 ? "é" : "ý"
                    }`
                  : translations.screenprintThermotransfer.comparison.button}
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput
                  placeholder={
                    translations.screenprintThermotransfer.comparison
                      .placeholder
                  }
                />
                <CommandEmpty>
                  {translations.screenprintThermotransfer.comparison.error}
                </CommandEmpty>
                <CommandGroup className="max-h-[300px] overflow-y-auto">
                  {textile_types.map((framework) => (
                    <CommandItem
                      key={framework.value}
                      value={framework.value}
                      onSelect={() => handleSelect(framework.value)}
                      className="cursor-pointer"
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value.includes(framework.value)
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      <span
                        className={cn(
                          value.includes(framework.value) ? "font-bold" : ""
                        )}
                      >
                        {framework.label}
                      </span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-10 max-w-[1024px] text-xl">
          <div className="self-stretch grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 items-center justify-center gap-10">
            {plastisolPesVisible && (
              <div className="rounded-2xl bg-slate-900 flex flex-col items-start justify-start p-8">
                <div className="self-stretch flex flex-col items-center justify-start gap-6">
                  <div className="self-stretch relative leading-[150%] font-medium">
                    {
                      translations.screenprintThermotransfer.comparison
                        .standardTransfer
                    }
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .ink
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .plastisol
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .glue
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .pes
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .washing
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          60°C
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .touch
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_2}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .flexibility
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_2}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .durability
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {plastisolTpuVisible && (
              <div className="flex-1 rounded-2xl bg-slate-900 overflow-hidden flex flex-col items-start justify-start p-8">
                <div className="self-stretch flex flex-col items-center justify-start gap-[24px_0px]">
                  <div className="self-stretch relative leading-[150%] font-medium">
                    {
                      translations.screenprintThermotransfer.comparison
                        .flexibleTransfer
                    }
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .ink
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .plastisol
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .glue
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .tpu
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .washing
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          40°C
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .touch
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_3}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .flexibility
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_4}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .durability
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {waterTpuVisible && (
              <div className="flex-1 rounded-2xl bg-slate-900 overflow-hidden flex flex-col items-start justify-start p-8">
                <div className="self-stretch flex flex-col items-center justify-start gap-[24px_0px]">
                  <div className="self-stretch relative leading-[150%] font-medium">
                    {
                      translations.screenprintThermotransfer.comparison
                        .waterFlexibleTransfer
                    }
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .ink
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .water
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .glue
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .tpu
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .washing
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          40°C
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .touch
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_4}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .flexibility
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_4}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .durability
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {waterPaVisible && (
              <div className="flex-1 rounded-2xl bg-slate-900 overflow-hidden flex flex-col items-start justify-start p-8">
                <div className="self-stretch flex flex-col items-center justify-start gap-[24px_0px]">
                  <div className="self-stretch relative leading-[150%] font-medium">
                    {
                      translations.screenprintThermotransfer.comparison
                        .highTempTransfer
                    }
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .ink
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .water
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .glue
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {translations.screenprintThermotransfer.comparison.pa}
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .washing
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          90°C
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .touch
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_1}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .flexibility
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_1}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .durability
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {water3DVisible && (
              <div className="flex-1 rounded-2xl bg-slate-900 overflow-hidden flex flex-col items-start justify-start p-8">
                <div className="self-stretch flex flex-col items-center justify-start gap-[24px_0px]">
                  <div className="self-stretch relative leading-[150%] font-medium">
                    {
                      translations.screenprintThermotransfer.comparison
                        .dddTransfer
                    }
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .ink
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .water
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .glue
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .tpu
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .washing
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          40°C
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .touch
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_1}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .flexibility
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_4}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .durability
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {siliconeVisible && (
              <div className="flex-1 rounded-2xl bg-slate-900 overflow-hidden flex flex-col items-start justify-start p-8">
                <div className="self-stretch flex flex-col items-center justify-start gap-[24px_0px]">
                  <div className="self-stretch relative leading-[150%] font-medium">
                    {
                      translations.screenprintThermotransfer.comparison
                        .siliconeTransfer
                    }
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .ink
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {
                            translations.screenprintThermotransfer.comparison
                              .silicone
                          }
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .glue
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          {translations.screenprintThermotransfer.comparison.na}
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .washing
                          }
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-start text-right">
                        <div className="relative leading-[150%] text-gradient">
                          60°C
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .touch
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_3}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .flexibility
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_5}
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                      <div className="flex-1 flex flex-row items-center justify-start gap-1">
                        <div className="relative leading-[150%]">
                          {
                            translations.screenprintThermotransfer.comparison
                              .durability
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start">
                        <img
                          className="w-24 relative h-5 overflow-hidden shrink-0"
                          alt=""
                          src={stars_5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <FAQ isTitleVisible={true} />
    </div>
  );
};

export default ScreenprintThermotransfer;
