import { FunctionComponent, useState, useRef } from "react";
import { UploadIcon } from "lucide-react";
import { IconFileCheck } from "@tabler/icons-react";
import { Button } from "../button";
import { useTranslation } from "../../../TranslationContext";

interface FileUploadProps {
  uploadText: string;
  fileSizeText: string;
  buttonText: string;
  onFileChange: (file: File | null) => void;
  isVerified?: boolean;
  helperText?: string; // Add this line
}

// Update the component definition to accept the props
const FileUpload: FunctionComponent<FileUploadProps> = ({
  uploadText,
  fileSizeText,
  buttonText,
  onFileChange,
  isVerified = true,
  helperText,
}) => {
  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const translations = useTranslation();

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      const acceptedFileTypes = [
        ".pdf",
        ".ai",
        ".eps",
        ".cdr",
        ".jpg",
        ".png",
        ".tif",
      ];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (!fileExtension || !acceptedFileTypes.includes(`.${fileExtension}`)) {
        alert("Nepodporovaný formát grafiky");
        return;
      }

      if (file.size > 20 * 1024 * 1024) {
        // 20MB
        alert("Grafika presahuje 20MB");
      } else {
        setSelectedFile(file);
        e.dataTransfer.clearData();
      }
      onFileChange(file);
    }
  };

  const handleButtonClick = () => {
    if (selectedFile) {
      // If a file is selected, clear the selection when the button is clicked
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      // If no file is selected, open the file explorer when the button is clicked
      fileInputRef.current?.click();
    }
    onFileChange(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 20 * 1024 * 1024) {
        // 20MB
        alert("Grafika presahuje 20MB");
      } else {
        setSelectedFile(file);
      }
      onFileChange(file);
    }
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center">
      <div
        className={`relative rounded-lg bg-slate-50 box-border w-full h-full flex flex-col items-center justify-center text-center text-sm text-slate-500 font-text-sm-font-normal border-[2px] border-dashed py-16 ${
          dragging
            ? "bg-slate-200 border-slate-300"
            : isVerified
            ? "border-slate-200"
            : "border-red-500"
        }`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the file input element
          onChange={handleFileChange}
          accept=".pdf,.ai,.eps,.cdr,.jpg,.png,.tif"
        />
        <div className="flex flex-col items-center justify-start gap-[16px]">
          <div className="flex flex-col items-center justify-start gap-[8px]">
            {selectedFile ? (
              <IconFileCheck size={36} className="text-green-500" />
            ) : (
              <UploadIcon size={36} />
            )}
            <div className="relative text-lg leading-[150%] max-w-[400px] flex flex-col sm:text-base overflow-auto break-all">
              {selectedFile ? selectedFile.name : uploadText}
            </div>
            <div className="relative text-sm leading-[150%] font-bold">
              {selectedFile
                ? `${(selectedFile.size / 1024 / 1024).toFixed(2)} MB`
                : fileSizeText}
            </div>
          </div>
          <Button
            variant={selectedFile ? "alternative" : "default"}
            size="default"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleButtonClick();
              // other button logic here
            }}
          >
            {selectedFile ? translations.fileUpload.deleteFile : buttonText}
          </Button>
        </div>
      </div>
      {helperText && (
        <div className="self-stretch relative text-sm leading-[150%] text-slate-500 pt-1">
          {helperText}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
