"use client";

import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { Check, ChevronDown } from "lucide-react";

import { cn } from "src/lib/utils";
import InfoIcon from "../../../assets/custom_icons/InfoIcon";

interface SelectProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> {
  className?: string;  // Adding className explicitly
}

const Select: React.FC<SelectProps> = ({ className, children, ...props }) => {
  return (
    <div className={className}>
      <SelectPrimitive.Root {...props}>
        {children}
      </SelectPrimitive.Root>
    </div>
  );
};

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

// Add new props
interface SelectTriggerProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  label?: string;
  optional?: boolean;
  helpText?: string;
  isVerified?: boolean; // Add this line
}

interface SelectItemProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> {
  icon?: React.ReactNode; // Prop for the icon
  link?: string; // New prop for the link
}

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(
  (
    {
      className,
      children,
      label,
      optional,
      helpText,
      isVerified = true,
      ...props
    },
    ref
  ) => (
    <div className="relative w-full flex flex-col items-start justify-start text-left text-sm text-slate-800 font-leading-tight-text-sm-font-normal">
      <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
        <div className="flex flex-row items-end justify-start gap-[2px]">
          <div className="relative leading-[150%] text-md font-semibold">
            {label}
          </div>
          {optional && (
            <div className="pl-2 relative leading-[150%] text-md font-semibold text-slate-400">
              - Optional
            </div>
          )}
        </div>
        {helpText && (
          <span className="inline-block relative" title={helpText}>
            <InfoIcon className="h-5 w-5" color="#64748b" />
          </span>
        )}
      </div>
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          "focus-visible:outline-none self-stretch rounded-md bg-slate-50 box-border h-10 flex flex-col items-start justify-start p-3 text-slate-800 border-[1px] border-solid border-slate-200",
          className, // Ensure this is correctly applying external classes
          !isVerified && "border-red-500 bg-red-50 text-red-700"
        )}
        {...props}
      >
        <div className="self-stretch flex flex-row items-center justify-start">
          <div className="self-stretch flex-1 relative leading-[125%] flex items-center">
            {children}
          </div>
          <ChevronDown className="relative w-4 h-4" color="#64748b" />
        </div>
      </SelectPrimitive.Trigger>
    </div>
  )
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "relative z-50 w-full overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        position === "popper" &&
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        className
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          "p-1",
          position === "popper" &&
            "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;



const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  SelectItemProps
>(({ className, children, icon, link, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    {...props}
    onClick={(e) => {
      // Prevent the dropdown from closing when the link is clicked
      e.stopPropagation();
    }}
  >
    {link ? (
      <a href={link} className="flex items-center w-full h-full text-inherit">
        {icon && (
          <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            {icon}
          </span>
        )}
        <span className="w-full flex items-center"> {/* Adjust padding if necessary */}
          {children}
        </span>
      </a>
    ) : (
      <>
        {icon && (
          <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            {icon}
          </span>
        )}
        <span className="w-full flex items-center"> {/* Adjust padding if necessary */}
          {children}
        </span>
      </>
    )}
  </SelectPrimitive.Item>
));

SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-muted", className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
};
