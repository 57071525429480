import React from "react";

const OrderIcon: React.FC = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.04981C0 3.63153 3.58172 0.0498047 8 0.0498047H40C44.4183 0.0498047 48 3.63153 48 8.0498V40.0498C48 44.4681 44.4183 48.0498 40 48.0498H8C3.58172 48.0498 0 44.4681 0 40.0498V8.04981Z"
      fill="url(#paint0_linear_808_24818)"
    />
    <g clipPath="url(#clip0_808_24818)">
      <mask
        id="mask0_808_24818"
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="24"
        height="25"
      >
        <path d="M12 12.0498H36V36.0498H12V12.0498Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_808_24818)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9999 14.0498C26.5522 14.0498 26.9999 14.4975 26.9999 15.0498V19.0498H30.9999C31.5522 19.0498 31.9999 19.4975 31.9999 20.0498C31.9999 20.6021 31.5522 21.0498 30.9999 21.0498H26.9999C26.4695 21.0498 25.9608 20.8391 25.5857 20.464C25.2106 20.0889 24.9999 19.5802 24.9999 19.0498V15.0498C24.9999 14.4975 25.4476 14.0498 25.9999 14.0498Z"
          fill="url(#paint1_linear_808_24818)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9999 16.0498C18.7347 16.0498 18.4803 16.1552 18.2928 16.3427C18.1053 16.5302 17.9999 16.7846 17.9999 17.0498V31.0498C17.9999 31.315 18.1053 31.5694 18.2928 31.7569C18.4803 31.9445 18.7347 32.0498 18.9999 32.0498H28.9999C29.2651 32.0498 29.5195 31.9445 29.707 31.7569C29.8945 31.5694 29.9999 31.315 29.9999 31.0498V20.464L25.5857 16.0498H18.9999ZM16.8786 14.9285C17.4412 14.3659 18.2043 14.0498 18.9999 14.0498H25.9999C26.2651 14.0498 26.5195 14.1552 26.707 14.3427L31.707 19.3427C31.8945 19.5302 31.9999 19.7846 31.9999 20.0498V31.0498C31.9999 31.8455 31.6838 32.6085 31.1212 33.1711C30.5586 33.7337 29.7956 34.0498 28.9999 34.0498H18.9999C18.2043 34.0498 17.4412 33.7337 16.8786 33.1711C16.316 32.6085 15.9999 31.8455 15.9999 31.0498V17.0498C15.9999 16.2542 16.316 15.4911 16.8786 14.9285ZM19.9999 19.0498C19.9999 18.4975 20.4476 18.0498 20.9999 18.0498H21.9999C22.5522 18.0498 22.9999 18.4975 22.9999 19.0498C22.9999 19.6021 22.5522 20.0498 21.9999 20.0498H20.9999C20.4476 20.0498 19.9999 19.6021 19.9999 19.0498ZM19.9999 25.0498C19.9999 24.4975 20.4476 24.0498 20.9999 24.0498H26.9999C27.5522 24.0498 27.9999 24.4975 27.9999 25.0498C27.9999 25.6021 27.5522 26.0498 26.9999 26.0498H20.9999C20.4476 26.0498 19.9999 25.6021 19.9999 25.0498ZM23.9999 29.0498C23.9999 28.4975 24.4476 28.0498 24.9999 28.0498H26.9999C27.5522 28.0498 27.9999 28.4975 27.9999 29.0498C27.9999 29.6021 27.5522 30.0498 26.9999 30.0498H24.9999C24.4476 30.0498 23.9999 29.6021 23.9999 29.0498Z"
          fill="url(#paint2_linear_808_24818)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_808_24818"
        x1="0"
        y1="0.0498047"
        x2="57.7461"
        y2="27.683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBFAFC" />
        <stop offset="0.354167" stopColor="#FFF4FF" />
        <stop offset="0.677083" stopColor="#FEE6FF" />
        <stop offset="0.911458" stopColor="#FEF1FF" />
        <stop offset="1" stopColor="#FCF7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_808_24818"
        x1="15.9999"
        y1="14.0498"
        x2="36.6326"
        y2="21.9485"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stopColor="#A554E5" />
        <stop offset="0.666667" stopColor="#FF00D6" />
        <stop offset="1" stopColor="#A554E5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_808_24818"
        x1="15.9999"
        y1="14.0498"
        x2="36.6326"
        y2="21.9485"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stopColor="#A554E5" />
        <stop offset="0.666667" stopColor="#FF00D6" />
        <stop offset="1" stopColor="#A554E5" />
      </linearGradient>
      <clipPath id="clip0_808_24818">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12.0498)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default OrderIcon;
