import { Button } from "../ui/button";
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import PageHeader from "../ui/custom/page_header";
import TextContainer from "../ui/custom/text_container";
import DOMpurify from "dompurify";
import rehypeRaw from "rehype-raw";
import rehypeSmoothScroll from "../../utils/rehypeSmoothScroll";
import Cookies from "js-cookie";
import { useTranslation } from "../../TranslationContext";

const CookiesPolicy = () => {
  const translations = useTranslation();

  const resetPreferences = () => {
    Cookies.remove("cookies-preference");
    // Optionally, you can also redirect or refresh the page to ensure the changes take effect immediately
    window.location.reload();
  };

  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(translations.cookies.markdownRoute)
      .then((response) => response.text())
      .then((text) => {
        const sanitizedMarkdown = DOMpurify.sanitize(text);
        setMarkdown(sanitizedMarkdown);
      })
      .catch((error) =>
        console.error("Error fetching privacy policy markdown:", error)
      );
  }, []);

  return (
    <div>
      <PageHeader title={translations.cookies.pageTitle} />
      <TextContainer>
        <p className="markdown">
          <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSmoothScroll]}>
            {markdown}
          </ReactMarkdown>
        </p>
        <Button variant="default" onClick={resetPreferences} className="mt-4">
          {translations.cookies.resetPreferencesButton}
        </Button>

        <p className="pt-20 pb-2 uppercase font-semibold">
          {translations.cookies.previousVersionsTitle}
        </p>
        <p className="m-0">
          <a
            href={translations.cookies.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {translations.cookies.currentVersionLink}
          </a>
        </p>
      </TextContainer>
    </div>
  );
};

export default CookiesPolicy;
