import { FunctionComponent } from "react";
import screenprint_hero from "../../assets/png/screenprint_hero.png";
import { useTranslation } from "../../TranslationContext";

const Hero: FunctionComponent = () => {
  const translations = useTranslation();
  return (
    <div
      id="hero"
      className="relative bg-white w-full overflow-hidden flex flex-col px-16 py-10 box-border items-center justify-center text-left text-5xl text-black font-text-xl-font-normal md:text-4xl sm:text-3xl lg:leading-tight sm:px-12 xs:px-6"
    >
      <div className="w-full flex flex-row items-center justify-between max-w-[1400px] lg:flex-col md:gap-8">
        <div className="self-stretch flex-1 flex flex-col items-start justify-center max-w-[644px] lg:max-w-[800px] sm:max-w-[644px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[125%] font-extrabold">
                <h1>{translations.hero.title}</h1>
              </div>
              <div className="self-stretch relative leading-[125%] font-extrabold text-gradient">
                <h1>{translations.hero.highlight}</h1>
              </div>
            </div>
            <div className="self-stretch relative text-xl leading-[150%] text-slate-800">
              <h2 className="text-xl leading-[150%] text-slate-800 sm:text-base">
                {translations.hero.description}
              </h2>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start pl-10 max-w-2xl xl:max-w-xl lg:max-w-lg xs:max-w-sm">
          <img
            src={screenprint_hero}
            alt={translations.hero.imgAlt}
            className="self-stretch"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
