import React, { useState } from "react";

interface GalleryProps {
  images: string[];
}

const GalleryComponent: React.FC<GalleryProps> = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleClick = (image: string) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-6">
        {images.map((image: string, index: number) => (
          <img
            key={index}
            src={image}
            alt=""
            onClick={() => handleClick(image)}
            className={`cursor-pointer object-cover w-full h-64 rounded-xl ${
              index >= 9 ? "hidden" : ""
            } ${index >= 8 ? "lg:hidden" : ""}
              ${index >= 4 ? "sm:hidden" : ""}`}
          />
        ))}
      </div>
      {selectedImage && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={handleClose}
        >
          <img src={selectedImage} alt="" className="max-h-full max-w-full" />
        </div>
      )}
    </div>
  );
};

export default GalleryComponent;
