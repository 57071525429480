import React from "react";

const ShirtIcon: React.FC = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="url(#paint0_linear_1140_20974)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.5847 15.1888C21.8455 15.3767 22 15.6786 22 16C22 16.5304 22.2107 17.0391 22.5858 17.4142C22.9609 17.7893 23.4696 18 24 18C24.5304 18 25.0391 17.7893 25.4142 17.4142C25.7893 17.0391 26 16.5304 26 16C26 15.6786 26.1545 15.3767 26.4153 15.1888C26.6761 15.0008 27.0113 14.9497 27.3162 15.0513L33.3162 17.0513C33.7246 17.1874 34 17.5696 34 18V23C34 23.5523 33.5523 24 33 24H31V31C31 31.5304 30.7893 32.0391 30.4142 32.4142C30.0391 32.7893 29.5304 33 29 33H19C18.4696 33 17.9609 32.7893 17.5858 32.4142C17.2107 32.0391 17 31.5304 17 31V24H15C14.4477 24 14 23.5523 14 23V18C14 17.5696 14.2754 17.1874 14.6838 17.0513L20.6838 15.0513C20.9887 14.9497 21.3239 15.0008 21.5847 15.1888ZM16 18.7208V22H18C18.5523 22 19 22.4477 19 23V31H29V23C29 22.4477 29.4477 22 30 22H32V18.7208L27.7782 17.3135C27.5818 17.8784 27.2593 18.3975 26.8284 18.8284C26.0783 19.5786 25.0609 20 24 20C22.9391 20 21.9217 19.5786 21.1716 18.8284C20.7406 18.3975 20.4182 17.8784 20.2218 17.3135L16 18.7208Z" fill="url(#paint1_linear_1140_20974)"/>
    <defs>
      <linearGradient id="paint0_linear_1140_20974" x1="0" y1="0" x2="57.7461" y2="27.6332" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FBFAFC"/>
        <stop offset="0.354167" stopColor="#FFF4FF"/>
        <stop offset="0.677083" stopColor="#FEE6FF"/>
        <stop offset="0.911458" stopColor="#FEF1FF"/>
        <stop offset="1" stopColor="#FCF7FF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1140_20974" x1="14" y1="15" x2="37.0533" y2="27.2574" gradientUnits="userSpaceOnUse">
        <stop offset="0.015625" stopColor="#A554E5"/>
        <stop offset="0.666667" stopColor="#FF00D6"/>
        <stop offset="1" stopColor="#A554E5"/>
      </linearGradient>
    </defs>
  </svg>
);

export default ShirtIcon;