import React from "react";

const ScreenprintIcon: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
      fill="url(#paint0_linear_1308_45445)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.5602 14.9725C22.3059 13.8766 23.7987 13.5927 24.8946 14.3384L32.7901 19.7107C33.8859 20.4564 34.1698 21.9493 33.4242 23.0451L32.1916 24.8565L32.5659 25.1112C33.6618 25.8569 33.9456 27.3497 33.2 28.4456L31.1122 31.5139C30.3665 32.6097 28.8737 32.8936 27.7779 32.148L15.2098 23.5962C14.1139 22.8505 13.83 21.3577 14.5757 20.2618L16.6635 17.1935C17.4091 16.0977 18.902 15.8138 19.9978 16.5594L20.3277 16.7839L21.5602 14.9725ZM23.7695 15.9919L31.665 21.3643C31.8476 21.4885 31.8949 21.7374 31.7706 21.92L30.5568 23.7039L21.9999 17.8815L23.2137 16.0976C23.338 15.9149 23.5868 15.8676 23.7695 15.9919ZM16.3349 21.9427L28.903 30.4944C29.0856 30.6187 29.3344 30.5714 29.4587 30.3888L31.5465 27.3205C31.6707 27.1378 31.6234 26.889 31.4408 26.7647L18.8727 18.213C18.6901 18.0887 18.4413 18.136 18.317 18.3186L16.2292 21.3869C16.1049 21.5696 16.1522 21.8184 16.3349 21.9427Z"
      fill="url(#paint1_linear_1308_45445)"
    />
    <path
      d="M18.06 34.29C17.37 34.29 16.67 34.06 16.11 33.6C14.95 32.67 14.66 31.07 15.42 29.8L16.89 27.62C17 27.45 17.14 27.32 17.31 27.21C17.95 26.81 18.82 26.99 19.24 27.62L20.68 29.75C21.47 31.06 21.18 32.66 20.02 33.6C19.46 34.06 18.76 34.29 18.07 34.29H18.06ZM18.06 29.46L17.11 30.88C16.9 31.24 16.99 31.75 17.37 32.06C17.77 32.38 18.36 32.38 18.76 32.06C19.14 31.76 19.23 31.24 18.99 30.84L18.07 29.47L18.06 29.46Z"
      fill="url(#paint2_linear_1308_45445)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1308_45445"
        x1="0"
        y1="0"
        x2="57.7461"
        y2="27.6332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBFAFC" />
        <stop offset="0.354167" stop-color="#FFF4FF" />
        <stop offset="0.677083" stop-color="#FEE6FF" />
        <stop offset="0.911458" stop-color="#FEF1FF" />
        <stop offset="1" stop-color="#FCF7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1308_45445"
        x1="14.1597"
        y1="13.9224"
        x2="38.1325"
        y2="25.0071"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#A554E5" />
        <stop offset="0.666667" stop-color="#FF00D6" />
        <stop offset="1" stop-color="#A554E5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1308_45445"
        x1="14.1597"
        y1="13.9224"
        x2="38.1325"
        y2="25.0071"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#A554E5" />
        <stop offset="0.666667" stop-color="#FF00D6" />
        <stop offset="1" stop-color="#A554E5" />
      </linearGradient>
    </defs>
  </svg>
);

export default ScreenprintIcon;
