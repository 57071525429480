import React from "react";
import FAQ from "../sections/faq";
import { Button } from "../ui/button";
import PageHeader from "../ui/custom/page_header";
import TextContainer from "../ui/custom/text_container";
import screenprint_transfer from "../../assets/png/screenprint_transfer.png";
import check from "../../assets/svg/check.svg";
import close from "../../assets/svg/close.svg";
import stars_1 from "../../assets/svg/stars_1.svg";
import stars_2 from "../../assets/svg/stars_2.svg";
import stars_3 from "../../assets/svg/stars_3.svg";
import stars_4 from "../../assets/svg/stars_4.svg";
import stars_5 from "../../assets/svg/stars_5.svg";
import { useTranslation } from "../../TranslationContext";

const Screenprint = () => {
  const translations = useTranslation();
  return (
    <div>
      <PageHeader
        title={translations.screenprint.pageHeader.title}
        subtitle={translations.screenprint.pageHeader.subtitle}
        background="default"
      />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start py-[92px] px-20 md:px-10 box-border text-left text-4xl lg:text-3xl xs:text-2xl text-slate-900 font-text-lg-font-normal">
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="w-full flex flex-row md:flex-col-reverse items-center justify-center max-w-[1200px]">
            {/* <div className="w-full md:max-w-[400px] self-stretch flex flex-col items-center justify-center">
              <img
                className="w-full min-w-[320px] relative h-fit overflow-hidden shrink-0"
                alt=""
                src={screenprint_transfer}
              />
            </div> */}
            <div className="self-stretch flex flex-col items-start justify-start gap-6">
              <b className="self-stretch relative leading-[150%]">
                <h1>{translations.screenprint.descriptionSection.title}</h1>
              </b>
              <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-500">
                <p className="m-0">
                  {translations.screenprint.descriptionSection.description1}
                </p>
                <br />
                <p className="m-0">
                  {translations.screenprint.descriptionSection.description2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-white flex flex-col items-center justify-center pt-18 px-20 md:px-10 sm:px-6 pb-24 box-border gap-16 text-center text-4xl lg:textl-3xl xs:text-2xl text-slate-900">
        <div className="w-full flex flex-col items-center justify-start max-w-[800px]">
          <b className="self-stretch relative leading-[150%]">
            {translations.screenprint.prosAndCons.title}
          </b>
        </div>
        <div className="w-full flex flex-row md:flex-col items-start justify-center gap-10 max-w-[1080px] text-left text-xl md:text-lg text-slate-950">
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.screenprint.prosAndCons.pros.durability}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprint.prosAndCons.pros
                      .durabilityDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.screenprint.prosAndCons.pros.ironing}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.screenprint.prosAndCons.pros.ironingDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.screenprint.prosAndCons.pros.texture}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.screenprint.prosAndCons.pros.textureDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={check}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.screenprint.prosAndCons.pros.effects}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.screenprint.prosAndCons.pros.effectsDescription}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.screenprint.prosAndCons.cons.moq}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.screenprint.prosAndCons.cons.moqDescription}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.screenprint.prosAndCons.cons.placement}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {
                    translations.screenprint.prosAndCons.cons
                      .placementDescription
                  }
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-3">
              <img
                className="w-9 relative h-9 overflow-hidden shrink-0"
                alt=""
                src={close}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-1.5">
                <b className="max-w-[580px] relative leading-[150%] inline-block">
                  {translations.screenprint.prosAndCons.cons.color}
                </b>
                <div className="self-stretch relative text-lg md:text-base leading-[150%] text-slate-700">
                  {translations.screenprint.prosAndCons.cons.colorDescription}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-slate-950 flex flex-col items-center justify-center py-24 px-20 md:py-10 box-border gap-16 text-left text-4xl lg:textl-3xl xs:text-2xl text-white sm:px-6">
        <div className="w-full flex flex-col items-center justify-start gap-4 max-w-[800px] text-center">
          <b className="self-stretch relative leading-[150%]">
            {translations.screenprint.comparison.title}
          </b>
          <div className="self-stretch relative text-lg leading-[150%] text-slate-300">
            {translations.screenprint.comparison.description}
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-10 max-w-[1024px] text-xl">
          <div className="self-stretch grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 items-center justify-center gap-10">
            <div className="rounded-2xl bg-slate-900 flex flex-col items-start justify-start p-8">
              <div className="self-stretch flex flex-col items-center justify-start gap-6">
                <div className="self-stretch relative leading-[150%] font-medium">
                  {translations.screenprint.comparison.plastisol}
                </div>
                <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.washing}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-start text-right">
                      <div className="relative leading-[150%] text-gradient">
                        60°C
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.ironing}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-start text-right">
                      <div className="relative leading-[150%] text-gradient">
                        {translations.screenprint.comparison.no}
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.touch}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_2}
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.flexibility}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_3}
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.durability}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 rounded-2xl bg-slate-900 overflow-hidden flex flex-col items-start justify-start p-8">
              <div className="self-stretch flex flex-col items-center justify-start gap-[24px_0px]">
                <div className="self-stretch relative leading-[150%] font-medium">
                  {translations.screenprint.comparison.water}
                </div>
                <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.washing}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-start text-right">
                      <div className="relative leading-[150%] text-gradient">
                        90°C
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.ironing}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-start text-right">
                      <div className="relative leading-[150%] text-gradient">
                        {translations.screenprint.comparison.yes}
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.touch}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_4}
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.flexibility}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_4}
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.durability}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 rounded-2xl bg-slate-900 overflow-hidden flex flex-col items-start justify-start p-8">
              <div className="self-stretch flex flex-col items-center justify-start gap-[24px_0px]">
                <div className="self-stretch relative leading-[150%] font-medium">
                  {translations.screenprint.comparison.silicone}
                </div>
                <div className="self-stretch flex flex-col items-center justify-start gap-2 text-base text-slate-100">
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.washing}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-start text-right">
                      <div className="relative leading-[150%] text-gradient">
                        90°C
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.ironing}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-start text-right">
                      <div className="relative leading-[150%] text-gradient">
                        {translations.screenprint.comparison.yes}
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.touch}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_5}
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.flexibility}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_5}
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-1.5">
                    <div className="flex-1 flex flex-row items-center justify-start gap-1">
                      <div className="relative leading-[150%]">
                        {translations.screenprint.comparison.durability}
                      </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      <img
                        className="w-24 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src={stars_5}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQ isTitleVisible={true} />
    </div>
  );
};

export default Screenprint;
