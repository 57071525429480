import React from "react";
import PageHeader from "../ui/custom/page_header";
import TextContainer from "../ui/custom/text_container";
import { useTranslation } from "../../TranslationContext";

const DeliveryPolicy = () => {
  const translations = useTranslation();
  return (
    <div>
      <PageHeader title={translations.deliveryPolicy.pageTitle} />
      <TextContainer>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.disclaimer}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.section1.title}
        </p>
        <p className="m-0 flex flex-col gap-1">
          {translations.deliveryPolicy.section1.content1}
          <p className="font-semibold text-fuchsia-600">
            {translations.deliveryPolicy.section1.highlight}
          </p>
          {translations.deliveryPolicy.section1.content2}
        </p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.section2.title}
        </p>
        <p className="m-0">{translations.deliveryPolicy.section2.content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.section3.title}
        </p>
        <p className="m-0">{translations.deliveryPolicy.section3.content}</p>
        <div className="mt-4 m-0 flex">
          <p className="w-36">
            {translations.deliveryPolicy.section3.country1}
          </p>
          <p>{translations.deliveryPolicy.section3.price1}</p>
        </div>
        <div className="mt-4 m-0 flex">
          <p className="w-36">
            {translations.deliveryPolicy.section3.country2}
          </p>
          <p>{translations.deliveryPolicy.section3.price2}</p>
        </div>
        <div className="mt-4 m-0 flex">
          <p className="w-36">
            {translations.deliveryPolicy.section3.country3}
          </p>
          <p>{translations.deliveryPolicy.section3.price3}</p>
        </div>
        <div className="mt-4 m-0 flex">
          <p className="w-36">
            {translations.deliveryPolicy.section3.country4}
          </p>
          <p>{translations.deliveryPolicy.section3.price4}</p>
        </div>
        <div className="mt-4 m-0 flex">
          <p className="w-36">
            {translations.deliveryPolicy.section3.country5}
          </p>
          <p>{translations.deliveryPolicy.section3.price5}</p>
        </div>
        <div className="mt-4 m-0 flex">
          <p className="w-36">
            {translations.deliveryPolicy.section3.country6}
          </p>
          <p>{translations.deliveryPolicy.section3.price6}</p>
        </div>
        <div className="mt-4 m-0 flex">
          <p className="w-36">
            {translations.deliveryPolicy.section3.country7}
          </p>
          <p>{translations.deliveryPolicy.section3.price7}</p>
        </div>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.section4.title}
        </p>
        <p className="m-0">{translations.deliveryPolicy.section4.content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.section5.title}
        </p>
        <p className="m-0">{translations.deliveryPolicy.section5.content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.section6.title}
        </p>
        <p className="m-0">{translations.deliveryPolicy.section6.content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0 pb-2 uppercase font-semibold">
          {translations.deliveryPolicy.section7.title}
        </p>
        <p className="m-0">{translations.deliveryPolicy.section7.content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">{translations.deliveryPolicy.contactInfo}</p>
      </TextContainer>
    </div>
  );
};

export default DeliveryPolicy;
